import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Category } from './Category';
import { PrimaryCategory } from './PrimaryCategory';

interface Categories{
    categories?:PrimaryCategory[];
}

export function useCategoryInit():Categories {
  const [categories, setCategories] = useState<Categories>({categories: []}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
    
    const subscriber = db.collection("category")
    .onSnapshot((querySnapshot) => {
        var data:PrimaryCategory[] = [];
        querySnapshot.forEach((doc) => {
            data = doc.data().category;
        });
        var sorted = data.slice(0);
        sorted.sort(function(a,b) {
            var x = a.name;
            var y = b.name;
            if(x && y){
                return x < y ? -1 : x > y ? 1 : 0;
            }else{
                return 0;
            }
        });
        const obj = { categories: sorted };
        setCategories(obj);
    });

    // Unsubscribe from events when no longer in use
    return subscriber;
  }, []);

  return categories;
}
