import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { IonBadge, IonItem, IonLabel, IonRow, IonToggle, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import Select, { StylesConfig } from 'react-select';
import { Product } from './Product';
import ProductService from './ProductService';
import CustomTextInput from '../../components/common/CustomTextInput';
import { useUserProductInit } from './UserProductData';
import { AppBar, Avatar, Box, Tab, Tabs } from '@material-ui/core';
import { image } from 'ionicons/icons';
import CustomButton from '../../components/common/CustomButton';
import FirestoreService from '../../services/FirestoreService';
import NativeApi from '../../services/NativeApi';
import { useCategoryInit } from '../category/CategoryData';
import { useUserSelectInit } from '../user/UserDataForProduct';
import { User } from '../user/User';
import { useSizeInit } from '../size/SizeData';
import { useColorInit } from '../color/ColorData';
import { usePatternInit } from '../pattern/PatternData';
import { Size } from '../size/Size';
import CustomLoading from '../../components/loading/CustomLoading';
import CanvasDraw from 'react-canvas-draw';
import { ImageInterface } from '../../interface/ImageInterface';
import { Color } from '../color/Color';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
const ntc = require('ntcjs');

export interface SelectOption {
  label: string,
  value: any,
} 
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ProductForm() {
  const classes = useStyles();
  const { size, orientation, width, height } = UseDeviceScreenSize();
  let landscape = width > height;
  let key = new URLSearchParams(window.location.search).get("key");
  let uid = new URLSearchParams(window.location.search).get("uid");
  const [value, setValue] = React.useState(0);
  const { navigate } = useContext(NavContext);
  const {sizes} = useSizeInit();
  const {colors} = useColorInit();
  const  {users}  = useUserSelectInit();
  const [userList, setUserList] = useState<SelectOption[]>([]);
  const [sizeList, setSizeList] = useState<SelectOption[]>([]);
  const [colorList, setColorList] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<Boolean>();
  const [ user, setUserData ] = useState<User>();
  const [image, setImageData] = React.useState<ImageInterface>({url: settings.imageUploadPlaceholder});
  const [colorsList, setColorsList] = useState<string[]>([]);
  const [ saveableCanvas, setSaveableCanvas ] = useState<any>();
  const [eventStart, setEventStart] = useState<boolean>(true);
  const [ scrollFromTop, setScrollFromTop ] = useState<number>(0);
  const [product, setProductData] = React.useState<Product>({
    key: '',
    error: "Enter valid data",
    errorShow: false,
    name: key? 'Fabric Name':'',
    code:  key? 'Product Code':'',
    mrp: 0 ,
    wsp: 0,
    sellPrice: 0 ,
    stampId: GlobalService.getCurrentDate().getTime()+"",
    description:  key? 'Description':'',
    status: true,
  });
  const [categories, setCategories] = React.useState<SelectOption[]>();
  let imageWidth = orientation && orientation === 'portrait' ? (width) : 400;
  let canvasView =  document.getElementById("canvas_view");
  let parentDiv =  document.getElementById("product-form-parent");

  useEffect(() => {
    const res = firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
    var products:Product[] = await ProductService.getUserProducts(uid ? uid : '');  
    if(products && key){
      var newProducts = products.filter(function( obj ) {
          return obj.key === key;
      });
      if(newProducts && newProducts.length > 0){
        console.log(newProducts[0])
        setProductData(newProducts[0]);
        if(newProducts[0].user){
          setUserData(newProducts[0].user);
        }
        if(newProducts[0].url){
          let img = new Image(); 
          img.src = newProducts[0].url;
          img.onload = function (event:any) {
                let  loadedImage:any = event.currentTarget;
                if(loadedImage){
                  let width = loadedImage.width;
                  let height = loadedImage.height;
                  var r = height/width;
                  var i:ImageInterface = {url: newProducts[0].url, width: imageWidth, height: r*imageWidth};
                  setImageData(i);
                }
          } 
        }
      }
    }
  });
    // Unsubscribe from events when no longer in use
    return res;
  }, []);


const submitForm = ()=>{
  if(!product.name || !product.categoryFullName || !product.mrp || !product.wsp || !product.code || !product.code || !product.colorsCount || !product.colors || product.colorsCount!=product.colors?.length){
    product.errorShow = true;
    GlobalService.showtoast("product", "Please enter all required details with category", "error", undefined, undefined);
    setProductData({ ...product});
  }else{
    const fire = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
    if(user){
      product.user = user; 
      let res = ProductService.addProductData(product, product && product.user && product.user.id ? product.user.id : '');
      res.then((response)=>{
        if(response){
          if(key){
            GlobalService.showtoast(product.key, "Product updated", "info", undefined, undefined);
          }else{
            GlobalService.showtoast(product.key, "Product added", "success", undefined, undefined);
            navigate("/product","back","pop");
          }
        }
      });
    }else{
      GlobalService.showtoast("error", "User not found", "error", undefined, undefined);
    } 
    })
  }
}

const setData = (event:any) =>{
  const { name, value } = event.target;
  setProductData({ ...product, [name]: value ? GlobalService.capitalizeCamelCase(value) : value});
}

const setChecked = (val:Boolean) =>{
  if(product.name!='Fabric Name'){
    product.status = val;
    setProductData({ ...product});
  }
}

const loadSize = () =>{
  if(sizes){
    var list:SelectOption[] = [];
    for(var obj of sizes){
      if(obj && obj.name){
        list.push({label: obj.name + ' | '+obj.quantity+'Pcs', value: obj});
      }
    }
    setSizeList(list);
  }
}

const loadColors = () =>{
  if(colors){
    var list:SelectOption[] = [];
    for(var obj of colors){
      if(obj && obj.name){
        list.push({label: obj.name, value: obj});
      }
    }
    setColorList(list);
  }
}

const loadCategories = () =>{
  if(user?.categories){
    var list:SelectOption[] = [];
    for(var obj of user?.categories){
      if(obj){
        var n = obj.brand ? obj.brand+" -> " : '';
        var c1 = obj.primary ? obj.primary : '';
        var c2 = obj.sub ? ' | '+obj.sub : '';
        var c3 = obj.main ? ' | '+obj.main : '';
        var name = n+c1+c2+c3;
        list.push({label: name ? name : '', value: obj});
      }
    }
    setCategories(list);
  }
}

const uploadImage = (e: any) => {
  if(!image.url || image.url==settings.imageUploadPlaceholder){
    var imageurl = NativeApi.takePicture(0, 0, 0);
    imageurl.then((img) => {
      setLoading(true);
      firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
        var name = GlobalService.getCurrentDate().getTime()+"";
        var format = ".jpeg";
        var str = img.split("base64")[0];
        if(str.includes("png")){
          format = ".png";
        };
        var path = product.key ? product.key : product.stampId;
        var fileName = GlobalService.getCurrentDate().getTime()+"";
        var res = await FirestoreService.uploadPicture(img, uid+"/product/"+path+"/"+fileName+format);
        if (res) {
          var blob = await FirestoreService.uploadString(img, uid+"/product/"+path+"/"+fileName+".txt");
          if (blob) {
            GlobalService.showtoast('image', 'Image uploaded', 'success', undefined, undefined);
            product.image = res;
            product.blobPath = uid+"/product/"+path+"/"+fileName+".txt";
            product.url =  ProductService.getProductUrl(product);
            product.colors = [];
            product.thumbnail = product.url.split(fileName)[0]+fileName+settings.thumbnailSize+product.url.split(fileName)[1];
            // getTextFromImage(img);
            setProductData({ ...product});
            let img2 = new Image(); 
            img2.src = img;
            img2.onload = function (event:any) {
                  let  loadedImage:any = event.currentTarget;
                  if(loadedImage){
                    let width = loadedImage.width;
                    let height = loadedImage.height;
                    var r = height/width;
                    console.log(width +" "+height+" "+ r);
                    var i:ImageInterface = {url: img, width: imageWidth, height: r*imageWidth};
                    setImageData(i);
                    setLoading(false);
                  }
            } 
            canvasView?.removeEventListener("mousedown", Respond);
            if(orientation === 'portrait'){
              canvasView?.removeEventListener("touchstart",RespondMob);
            }
            if(key){
              submitForm();
            }
          }
        } else {
          setLoading(false);
        }
      });
    })
  }else{
    GlobalService.showtoast("imageerror","Please remove image before update.","error",undefined,undefined);
  }
}

async function removeImage(){
  if(product && product.image){
      setLoading(true);
      var res = await FirestoreService.removePicture(product.image ? product.image : '');
      if (res) {
        setLoading(false);
        GlobalService.showtoast('image', 'Image removed', 'success', undefined, undefined);
        product.image = settings.imageUploadPlaceholder;
        product.url = '';
        product.colors = [];
        if(product.key){
          submitForm();
        }
        var i:ImageInterface = {url: settings.imageUploadPlaceholder}
        setImageData(i)
        setProductData({ ...product});
        canvasView?.removeEventListener("mousedown", Respond);
        if(orientation === 'portrait'){
          canvasView?.removeEventListener("touchstart",RespondMob);
        }
      } else {
        setLoading(false);
      }
  }
}

const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  setValue(newValue);
};

const addSize = (opts:any) =>{
  if(opts && opts.length>0){
    var obj:Size[] = [];
    for(var opt of opts){
      obj.push(opt.value);
    }
    product.sizes = obj;
    setProductData({...product});
  }else{
    product.sizes = [];
    setProductData({...product});
  }
}

const addColor = (opts:any) =>{
    if(opts && opts.length>0 && product.colorsCount){
      if(opts.length <= product.colorsCount){
        var obj:Size[] = [];
        for(var i=0 ; i < opts.length ; i++){
          opts[i].id = i+1;
          obj.push(opts[i].value);
        }
        product.colors = obj;
        setProductData({...product});
        if(opts.length === product.colorsCount){
          
        }
      }else{
        GlobalService.showtoast("color","Cannot Select more colors","error",undefined,undefined);
      }
    }else{
      product.colors = [];
      setProductData({...product});
    }
}

const setCategory = (opt:any) =>{
  product.categoryBrand = opt.value;
  var n = opt.value.brand ? opt.value.brand+" -> " : '';
  var c1 = opt.value.primary ? opt.value.primary : '';
  var c2 = opt.value.sub ? ' | '+opt.value.sub : '';
  var c3 = opt.value.main ? ' | '+opt.value.main : '';
  product.category1 = opt.value.primary;
  if(opt.value.sub){
    product.category2 = opt.value.sub;
  }
  if(opt.value.main){
    product.category3 = opt.value.main;
  }
  product.brand = opt.value.brand;
  var name = n+c1+c2+c3;
  product.categoryFullName = name;
  setProductData({...product});
}

const setItemCount = ((opt:any) =>{
  if(product.colorsCount && product.colors){
    if(product.colors.length <= opt.value){
      product.colorsCount = opt.value;
      setProductData({...product});
    }else{
      product.colorsCount = opt.value;
      product.colors = [];
      setProductData({...product});
    }
  }else{
    product.colorsCount = opt.value;
    product.colors = [];
    setProductData({...product});
  }
  canvasView?.removeEventListener("mousedown", Respond);
  if(orientation === 'portrait'){
    canvasView?.removeEventListener("touchstart",RespondMob);
  }
});


function getPosition(obj:any) {
  var curleft = 0, curtop = 0;
  if (obj.offsetParent) {
      do {
          curleft += obj.offsetLeft;
          curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
      return { x: curleft, y: curtop };
  }
  return undefined;
}

function rgbToHex(r:number, g:number, b:number) {
  if (r > 255 || g > 255 || b > 255)
      throw "Invalid color component";
  return ((r << 16) | (g << 8) | b).toString(16);
}

const loadUser = () =>{
  if(users){
    var list:SelectOption[] = [];
    for(var user of users){
      var name = '';
      if(user.name){
        name = name + user.name;
      }
      if(user.firmName){
        name = name + " | "+user.firmName;
      }
      if(user.gst){
        name = name + " | "+user.gst;
      }
      list.push({label: name, value: user});
    }
    setUserList(list);
  }
}

const setUser = (opt:any) =>{
  console.log(opt);
  if(opt){
    product.user = opt.value;
    setUserData(opt.value);
    setProductData({ ...product});
    setValue(1);
  }
}

useEffect(()=>{
  if(canvasView && eventStart){
    if(orientation === 'portrait'){
      canvasView?.addEventListener("touchstart",RespondMob, {passive: true});
    }
    canvasView?.addEventListener("mousedown",Respond, {passive: true});
    parentDiv?.addEventListener('scroll', updatePosition, {passive: true});
    
  }
  return;
})

function updatePosition(e){
  var position = parentDiv?.scrollTop;
  setScrollFromTop(position ? position : 0);
  canvasView?.removeEventListener("mousedown", Respond);
  if(orientation === 'portrait'){
    canvasView?.removeEventListener("touchstart",RespondMob);
  }
}

function RespondMob(e) {
  console.log("yes");
  // e.preventDefault();
  if(product.colorsCount){
    if(product.colors && product.colors?.length >= product.colorsCount){
      // GlobalService.showtoast("colorerror","Cannot add more colors","error",undefined,undefined);

      return;
    }
    if(saveableCanvas){ 
      var canvas:any = e.target;
      var xDistance:number = 20;
      var yDistance:number = 100;
      try{
        if(canvas && canvas.nextElementSibling && canvas.nextElementSibling.nextElementSibling && canvas.nextElementSibling.nextElementSibling.nextElementSibling)
        var c:any = canvas.nextElementSibling.nextElementSibling.nextElementSibling;
        var context:any = c.getContext('2d');
        console.log(context)
        console.log(document.getElementById("canvas_view"))
        // var pos:any = getPosition(document.getElementById("canvas_view"));
        var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
        var pos:any = evt.touches[0] || evt.changedTouches[0];
        console.log(pos)
        console.log(pos.pageX)
        console.log(pos.pageY)
        var x = pos.pageX - xDistance;
        var y = pos.pageY - yDistance + scrollFromTop;
        console.log(x,y)
        var p = context.getImageData(x, y, 1, 1).data; 
        var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
        const n_match = ntc.name(hex);
        var color:Color = {name: '', code: hex}
        color.poseX = x;
        color.poseY = y;
        color.imageWidth = image.width;
        color.imageHeight = image.height;
        if(n_match && n_match[1]){
          color.name = n_match[1]
        }
        if(color.code){
          if(product.colors && product.colors.length>0){
              color.id = product.colors.length+1;
              if(!product.colors.find(e=> e.code === color.code)){
                // setEventStart(false);
                product.colors.push(color);
                setProductData({...product});
              }else{
                setColorsList([]);
              }
          }else{
            color.id = 1;
            product.colors = [];
            product.colors.push(color);
            setProductData({...product});
          }
          canvasView?.removeEventListener("mousedown", Respond);
          if(orientation === 'portrait'){
            canvasView?.removeEventListener("touchstart",RespondMob);
          }
        }
        
      }catch(err){

      }
    }
  }else{
    GlobalService.showtoast("colorerror","Select number of colors first","warning",undefined,"top-right");
  }
  canvasView?.removeEventListener("mousedown", Respond);
  if(orientation === 'portrait'){
    canvasView?.removeEventListener("touchstart",RespondMob);
  }
}


function Respond(e) {
  if(product.colorsCount){
    if(product.colors && product.colors?.length >= product.colorsCount){
      // GlobalService.showtoast("colorerror","Cannot add more colors","error",undefined,undefined);

      return;
    }
    if(saveableCanvas){ 
      var canvas:any = e.target;
      var xDistance:number = 0;
      var yDistance:number = 5;
      try{
        if(canvas && canvas.nextElementSibling && canvas.nextElementSibling.nextElementSibling && canvas.nextElementSibling.nextElementSibling.nextElementSibling)
        var c:any = canvas.nextElementSibling.nextElementSibling.nextElementSibling;
        var context:any = c.getContext('2d');
        var pos:any = getPosition(document.getElementById("canvas_view"));
        console.log(e.pageX)
        console.log(e.pageY)
        var x = e.pageX - xDistance;
        var y = e.pageY - yDistance;
        if(pos && pos.x && pos.y){
          x = e.pageX - pos.x - xDistance;
          y = e.pageY - pos.y - yDistance + scrollFromTop;
        }
        console.log(x,y)
        var p = context.getImageData(x, y, 1, 1).data; 
        var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
        const n_match = ntc.name(hex);
        var color:Color = {name: '', code: hex}
        color.poseX = x;
        color.poseY = y;
        color.imageWidth = image.width;
        color.imageHeight = image.height;
        if(n_match && n_match[1]){
          color.name = n_match[1]
        }
        if(color.code){
          if(product.colors && product.colors.length>0){
              color.id = product.colors.length+1;
              if(!product.colors.find(e=> e.code === color.code)){
                console.log("y")
                // setEventStart(false);
                product.colors.push(color);
                setProductData({...product});
              }else{
                setColorsList([]);
              }
          }else{
            color.id = 1;
            product.colors = [];
            product.colors.push(color);
            setProductData({...product});
          }
        }
        
      }catch(err){

      }
    }
  }else{
    GlobalService.showtoast("colorerror","Select number of colors first","warning",undefined,"top-right");
  }
  console.log("remove")
  canvasView?.removeEventListener("mousedown", Respond);
  if(orientation === 'portrait'){
    canvasView?.removeEventListener("touchstart",RespondMob);
  }
}

const removeColor = (color:Color) =>{
  if(product.colors?.find(e => e.code === color.code)){
      var list:Color[] = [];
      var count = 1;
      for(var c of product.colors){
        if(c.code != color.code){
          c.id = count;
          count++;
          list.push(c);
        }
      }
      product.colors = list;
      setProductData({...product});
  }
  canvasView?.removeEventListener("mousedown", Respond);
  if(orientation === 'portrait'){
    canvasView?.removeEventListener("touchstart",RespondMob);
  }
}

  return (
    <Container component="main">
       {loading ? <CustomLoading /> : ''}
      <CssBaseline />
      <div className={classes.paper} style={{marginTop: '0px'}}>
      <AppBar position="static" className={classes.appBarStyle}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="User" {...a11yProps(0)} />
          {product.user ? <Tab label="Basic" {...a11yProps(1)} /> : ''}
          {product.user ? <Tab label="Attribute" {...a11yProps(2)} /> : ''}
        </Tabs>
      </AppBar>
        <TabPanel value={value} index={0}>
        <Grid container justify="space-evenly" >
            <Grid container spacing={3}>
              <Grid item lg={12} sm={12} xs={12}>
                  <Select options={userList} value={{label: product.user ? product.user.name : 'Select User', value: product && product.user ? product.user : ''}} onFocus={loadUser} onChange={setUser} ></Select> 
                </Grid>
            </Grid>
        </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <div className={classes.paper} style={{marginTop: '20px'}}>
      <Grid container justify="space-evenly" >
            <Grid container spacing={3} style={{marginBottom: '20px'}}>
                <Grid item lg={3} md={5} sm={12} xs={12}>
                    <IonItem>
                        <IonLabel>Status: {product.status? 'Active': 'Disabled'}</IonLabel>
                        <IonToggle checked={product.status? true : false} onIonChange={e => setChecked(e.detail.checked)} />
                    </IonItem>
                </Grid>
                <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
                <Grid item lg={8} md={6} sm={12} xs={12}>
                  <Select options={categories} value={{label: product.categoryFullName ? product.categoryFullName : 'Select Category', value: product.categoryBrand ? product.categoryBrand : ''}} onFocus={loadCategories} onChange={setCategory} ></Select> 
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={4} sm={6} xs={12}>
                  <CustomTextInput error={product.errorShow && product.name==''}
                        errorText={product.error} required={true}
                        label="Fabric Name" name="name" autoComplete="name"
                        value={product.name} onchange={setData} />
                </Grid> 
                <Grid item lg={4} sm={6} xs={12}>
                  <CustomTextInput error={product.errorShow && product.code==''}
                        label="Article Number" name="code" autoComplete="code" required={true}
                        value={product.code} onchange={setData} />
                </Grid>
                <Grid item lg={4} sm={12} xs={12}>
                    <CustomTextInput
                        errorText={product.error} required={false}
                        label="Description" name="description" autoComplete="description"
                        value={product.description} onchange={setData} />
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                    <CustomTextInput error={product.errorShow && (!product.mrp || product.mrp < 0)}
                        errorText={product.error} required={true} type="number"
                        label="MRP" name="mrp"
                        value={product.mrp} onchange={setData} />
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                    <CustomTextInput error={product.errorShow && (!product.wsp || product.wsp < 0)}
                        errorText={product.error} required={true} type="number"
                        label="WSP" name="wsp"
                        value={product.wsp} onchange={setData} />
                </Grid>
                
                <Grid item lg={12} sm={12} xs={12}>
                    <IonLabel>Select Product Size</IonLabel>
                  </Grid>
                  {product.name!='Fabric Name' ? <Grid item lg={12} sm={12} xs={12}>
                  {product.sizes && product.sizes.length > 0 ? <Select defaultValue={
                    product.sizes.map((item,index)=>{
                      return {label: item.name + ' | '+item.quantity+'Pcs', value: item}
                    })
                  } isMulti options={sizeList} onFocus={loadSize} onChange={addSize} ></Select> : 
                  <Select isMulti options={sizeList} onFocus={loadSize} onChange={addSize} ></Select> } 
                </Grid> : ''}
                <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    className={landscape ? classes.submit : classes.submit2}
                    onClick={()=>{setValue(2)}}
               >
                  Next
                </Button>
                </Grid>
            </Grid>
        </Grid> 
      </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <div className={classes.paper} style={{padding: '0px !important'}}>
          <Grid container justify="space-evenly" style={{padding: '0px !important'}}>
              <Grid container>  
                <Grid item xs={12} sm={12} md={6} lg={6} id="canvas_view">
                  <div on-touch = {Respond}>
                   { image && image.url != settings.imageUploadPlaceholder ? <CanvasDraw 
                      ref={(canvasDraw) =>{setSaveableCanvas(canvasDraw);}}
                      imgSrc={image.url}
                      saveData= {null}
                      immediateLoading = {true}
                      hideInterface = {true}
                      lazyRadius= {1}
                      brushRadius={1}  
                      canvasWidth = {image.width ? image.width : '300'}
                      canvasHeight = {image.height ? image.height : '300'}
                      brushColor="#808080"
                      disabled={true}
                      loadTimeOffset= {5}
                      style={{
                          boxShadow:
                            "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
                          ,
                            position: 'relative',
                          }}
                      /> : <img src={image.url} style={{maxHeight: '200px', marginTop: '20px'}} />}
                  </div>
                  <Grid container >
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <CustomButton title="Upload Img" onClick={uploadImage} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                        <CustomButton title="Remove Img" onClick={removeImage} />
                    </Grid>
                  
                    </Grid>
                </Grid>  
                <Grid item xs={12} sm={4} md={6} lg={6}>
                   <div style={{marginTop: '20px', minWidth: '300px'}}>
                      {image && image.url!=settings.imageUploadPlaceholder ?  
                      <Grid item lg={12} sm={12} md={12} xs={12} style={{maxWidth: '300px'}}>
                        <IonLabel>Number of Colors</IonLabel>  
                        <Grid item xs={12} sm={12} lg={12} >
                            <Select onChange={setItemCount} defaultValue={{label: product.colorsCount ? product.colorsCount : '', value: product.colorsCount ? product.colorsCount : ''}}  placeholder="No. of Colors" options={[{label: '1', value:'1'},{label: '2', value:'2'},{label: '3', value:'3'},{label: '4', value:'4'},{label: '5', value:'5'},{label: '6', value:'6'},{label: '7', value:'7'},{label: '8', value:'8'},{label: '9', value:'9'},{label: '10', value:'10'},{label: '11', value:'11'},{label: '12', value:'12'},{label: '13', value:'13'},{label: '14', value:'14'},{label: '15', value:'15'},{label: '16', value:'16'},{label: '17', value:'17'},{label: '18', value:'18'},{label: '19', value:'19'},{label: '20', value:'20'}]}></Select> 
                        </Grid>
                      </Grid> : ''}
                       {product.colors && product.colors.length>0 ? product.colors.map((item,index)=>{
                          // var color:Color = {name: ''};
                          // if(product.colors && product.colors.length>0){
                          //   var val:any = product.colors.filter(e => e.name === item.name)[0];
                          //   if(val && val.name){
                          //     color = val;
                          //   }
                          // }
                          return (
                           <span key={'colorpallate'+index}>
                              {/* <span style={{position: 'absolute', whiteSpace: 'nowrap', width: '50px', overflow: 'hidden'}}>{item.name}</span> */}
                              <Button onClick={()=>{removeColor(item)}} className={classes.rectangleSize} style={{marginTop: '20px', backgroundColor: item.code, color: item.code && GlobalService.wc_hex_is_light(item.code) ? '#000000' : '#ffffff'}}>{item.id ? item.id :''}</Button>
                           </span>
                          )
                        }):''}
                    </div>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container justify="space-evenly" >
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Button
                              fullWidth
                              variant="contained"
                              className={landscape ? classes.submit : classes.submit2}
                              onClick={()=>{setValue(0)}}
                        >
                          Back
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            className={landscape ? classes.submit : classes.submit2}
                            onClick={submitForm}
                      >
                        {key? 'Update': 'Add'}
                        </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
          </Grid>
        </div>     
        </TabPanel>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  loginHeading: {
      color: 'var(--ion-text-color)',
  },
  container: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'flex-start',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    margin: theme.spacing(3, 0, 2),
    fontWeight: 'bold',
  },
  submit3: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-secondary)',
    margin: theme.spacing(3, 0, 2),
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
      margin: 0,
  },
  label:{
    fontSize: '16px',
  },
  header:{
    fontSize: '18px',
  },
  selectStyle: {

  },
  appBarStyle : {
    backgroundColor: 'var(--ion-color-primary)',
    color: 'var(--ion-color-primary-contrast)',
    marginTop: '10px',
  },
  large: {
    width: 'auto',
    height: '200px',
    maxHeight: '250px',
    maxWidth: '300px',
    objectFit: 'scale-down',
  },
  link: {
      color: 'var(--ion-color-primary)',
  },
  rectangleSize: {
    height: '50px',
    width: '50px',
    margin: '8px',
    background: '#fff',
    textAlign: 'center',
    minWidth: '30px !important',
    font: '14px Arial, sans-serif',
  },
}));