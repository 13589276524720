import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';import 'firebase/auth';
import { PreRegistered } from './PreRegistered';
import { db } from '../../App';

interface PreRegistereds{
    preRegistereds?:PreRegistered[];
}

export function usePreRegisteredInit():PreRegistereds {
  const [preRegistereds, setPreRegistereds] = useState<PreRegistereds>({preRegistereds: undefined}); // Initial empty array of tests 
  useEffect(() => {
    const subscriber = db.collection("preregistered")
      .onSnapshot((querySnapshot) => {
          const data:PreRegistered[] = [];
          var i = 1;
          querySnapshot.forEach((doc) => {
              var o = doc.data().preRegistered;
              o.id = i;
              i++;
              data.push(o);
          });
          const obj = { preRegistereds: data };
          setPreRegistereds(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
  }, []);

  return preRegistereds;
}
