import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import BrandComponentUser from '../../components/common/BrandComponentUser';
import { Brand } from '../brand/Brand';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    },
    card: {
        padding: '5px !important',
    }
  }),
);

export default function UserCategoryCard({item, removeItem}:any) {
  const classes = useStyles();
  var brand:Brand = {name: item.brand, sub: item.sub, url: item.brandLogo}
  console.log(item)
  return (
    <div>
    <Card>
      <CardContent sx={{ p: 1, pb: 5+'px !important' }}>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  height: "35px" }} variant="subtitle1" component="div">
            {brand && brand.name ? <BrandComponentUser titleSize="14px" subTitleSize="12px" logoSize="30px" height="35px" show={true} size1={3} size2={7} size1m={3} size2m={7} size1sm={4} size2sm={6} brand={brand} removeItem={removeItem}/> : 
            ''}
        </Typography>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  height: "35px" }} variant="subtitle1" component="div">
            {item.primary} {item.sub ? ' -> '+item.sub : ''} {item.main ? ' -> '+item.main : ''}
        </Typography>
      </CardContent>
    </Card>
    </div>
  );
}