import { Capacitor } from "@capacitor/core";
import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import { db } from "../../App";
import settings from "../../config/settings";
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import GlobalService from "../../services/GlobalService";
import { Order } from "./Order";

function GetOrder(){
    
}

export async function addOrderData(order:Order) { 
        let timestamp = GlobalService.getCurrentDate().getTime();
        var response = false;
        order.updatetimestamp = GlobalService.getCurrentDate().toDateString();
        if(!order.timestamp){
            order.timestamp = GlobalService.getCurrentDate().toLocaleString();
        }
        if(!order.date){
            order.date = GlobalService.getCurrentDate();
        }
        if(!order.key){
            order.key = 'k'+timestamp;
            order.status = "pending";
        }
        await db.collection("orders").doc(order.key).set({
            order
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getOrdersForUser(uid: string){
    var orders:Order[] = [];
    await db.collection("orders").where("order.user.id","==",uid).onSnapshot(async(snapshot) => {
        var id = 1;
        snapshot.forEach(async (doc)=>{
            var order:Order = await doc.data().order;
            order.id = id;
            id++;
            orders.push(order);
        });
    });
    return orders;
}

export async function getOrdersForSupplier(uid: string){
    var orders:Order[] = [];
    await db.collection("orders").where("order.supplier.id","==",uid).onSnapshot(async(snapshot) => {
        var id = 1;
        snapshot.forEach(async (doc)=>{
            var order:Order = await doc.data().order;
            order.id = id;
            id++;
            orders.push(order);
        });
    });
    return orders;
}

export async function getOrderByKey(uid: string, key: string){
    var order:Order = {};
    await db.collection("orders").doc(key)
    .get().then((doc) =>{
        var o:any = doc.data();
        order = o.order;
    });
    return order;
}

export function printPdf(order:Order){
    console.log("here")
        if(order && order.products && order.products.length>0){
          var quantity = 0;
        for(var item of order.products){
            var qty:number = item && item.size && item.size.quantity && item.quantity ? (parseInt(item.size.quantity+'')*item.quantity) : item.quantity ? item.quantity : 0;
            quantity = quantity + qty;
        }
        var list:any = Array.from(order.products, (item, index)=>([
          index + 1,
          item.code +' | '+ item.name,
          item.color?.id,
          item.size?.name,
          item.mrp +' / '+ item.wsp,
          (item && item.size && item.size.quantity && item.quantity ? (parseInt(item.size.quantity+'')*item.quantity) : item.quantity) +' Pcs',
          (parseFloat(item.mrp+"")*parseInt( (item && item.size && item.size.quantity && item.quantity ? (parseInt(item.size.quantity+'')*item.quantity) : item.quantity)+"")) +' / '+ (parseFloat(item.wsp+"")*parseInt( (item && item.size && item.size.quantity && item.quantity ? (parseInt(item.size.quantity+'')*item.quantity) : item.quantity)+"")),
        ]))
        var props:any = {
          outputType: Capacitor.isNative ? OutputType.DataUriString : OutputType.Save,
          returnJsPDFDocObject: true,
          fileName: "Order"+order.voucherNumber,
          orientationLandscape: false,
          compress: true,
          logo: {
            src: settings.printTop,
            width: 100, //aspect ratio = width/height
            height: 25,
              margin: {
                  top: 0, //negative or positive num, from the current position
                  left: 0 //negative or positive num, from the current position
              }
          },
          business: {
            name: "Order Number: "+order.voucherNumber,
            address: 'Date: '+ GlobalService.getIndianDateFormatFromDate(order.date ? order.date : ''),
            phone: 'Order Type: '+ (order.type ? order.type : 'Regular'), 
          },
          contact: {
              address: "Customer Firm: "+order.user?.firmName,
              phone: "Customer Id: "+order.user?.userId,
              email: "Brand: "+order.category?.brand,
              otherInfo: "Category: "+order.category?.sub,
              style: {
                fontSize: 12 //optional, default 12
              }
          },
          invoice: {
              invDate: "Delivery Start Date:__________________",
              invGenDate: "Last Date of Delivery:__________________",
              headerBorder: false,
              tableBodyBorder: false,
              header: [
                {
                  title: "SN.", 
                  style: { 
                    width: 10 
                  } 
                }, 
                { 
                  title: "Article Number",
                  style: {
                    width: 60
                  } 
                }, 
                { 
                  title: "Color"
                }, 
                {  
                  title: "Size"
                }, 
                { title: "MRP/WSP"},
                { title: "Quantity"},
                { title: "Total"}
              ],
              table: list,
              // invTotalLabel: "Total Approx Amount: "+GlobalService.convert2digitdecimalstring(order.total+'')+" + GST Applicable", 
              // invTotal: GlobalService.convert2digitdecimalstring(order.total+''),
              // invCurrency: "+ GST Applicable",
              
              row1: {
                  col3: "Total Approx Amount: "+GlobalService.convert2digitdecimalstring(order.total+'')+" + GST Applicable",
                  // col2: quantity ? quantity+'' : 0,
                  // col3: 'Pcs.',
                  style: {
                    fontSize: 10 //optional, default 12
                  }
              },
              row2: {
                  col3: 'Total Approx Quantity: '+(quantity ? quantity+'' : 0)+' Pcs.',
                  // col2: quantity ? quantity+'' : 0,
                  // col3: 'Pcs.',
                  style: {
                    fontSize: 10 //optional, default 12
                  }
              },
              // invDescLabel: "Invoice Note",
              invDesc: "No goods return of any order only goods return of mismatch article. Delivery time within 4-5 days of urgent order and within 1 week of regular order. Regular order cancelled within 12 hours by customer. and not called of urgent order.",
          },
          // footer: {
          //     text: "No goods return of any order only goods return of mismatch article.<br>"+
          //     "Delivery time within 4-5 days of urgent order and within 1 week of regular order."+
          //     "Regular order cancelled within 12 hours by customer. and not called of urgent order.",
          // },
          pageEnable: true,
          pageLabel: "Page ",
      };
      // setLoading(true)
        //or in browser
        if(Capacitor.isNative){
        //   var pdfObject:any = jsPDFInvoiceTemplate(props); //returns number of pages created
        //   var data = GlobalService.convertBase64ToBlob(pdfObject.dataUriString, 'application/pdf');
        //   GlobalService.saveAndOpenPdf(data, 'sample');
        //   console.log("printed");
          // setLoading(false)
        }else{
          var pdfObject:any = jsPDFInvoiceTemplate(props); //returns number of pages created
          // setLoading(false)
        }
       }
      
}

export async function removeOrder(uid: string){

}

export default { 
    GetOrder,
    addOrderData,
    getOrdersForUser,
    getOrdersForSupplier,
    getOrderByKey,
    removeOrder,
    printPdf,
}