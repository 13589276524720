import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Size } from './Size';

interface Sizes{
    sizes?:Size[];
}

export function useSizeInit():Sizes {
  const [sizes, setSizes] = useState<Sizes>({sizes: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("size").doc("size")
        .onSnapshot((querySnapshot) => {
            var data:Size[] = [];
            if(querySnapshot.exists){
                var obj:any = querySnapshot.data();
                    if(obj.size && obj.size.length>0){
                        var cs:Size[] = obj.size;
                        var count = 1;
                        for(var i=0; i<cs.length; i++){
                            cs[i].id = count;
                            count++;
                        }
                        data = cs;
                    }
            }
            var us:Sizes = {sizes: data};
            setSizes(us);
        });

        // Unsubscribe from events when no longer in use
        return subscriber;
  }, []);

  return sizes;
}
