import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app'; import 'firebase/auth';
import { Marketing } from './Marketing';

interface Marketings {
  marketings?: Marketing[];
}

interface marketingStatus {
  role?: string,
  status?: string,
}

export function useMarketingInit(): Marketings {
  const [marketings, setmarketings] = useState<Marketings>({ marketings: undefined }); // Initial empty array of marketings 
  useEffect(() => {
    var uid: any = "";
    if (firebase.auth().currentUser) {
      uid = firebase.auth().currentUser?.uid;
    }
    const subscriber = db.collection("marketings")
      .onSnapshot(async (querySnapshot) => {
        var data: Marketing[] = [];
        var c = 1;  
        await querySnapshot.forEach( async (doc) => {
          var res =  doc.data();
          var obj:Marketing = res.marketing; 
          obj.sn = c;
          c++;
          data.push(obj);
        });
        const o = { marketings: data };
        setmarketings(o);
      });
    // Unsubscribe from events when no longer in use
    return subscriber;
  }, []);

  return marketings;
}