import { IonPage, } from '@ionic/react';
import CustomFooter from '../../components/footer/CustomFooter';
import LoginForm from './LoginForm';

import './Login.css'

const Login: React.FC = () => {
  

  return (
    <IonPage className="loginBackground">
      <LoginForm />
      <CustomFooter />
    </IonPage>
  );
};

export default Login;