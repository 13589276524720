import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, IonToggle, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Tooltip from '@mui/material/Tooltip';
import SideMenu3 from '../../components/menu/SideMenu3';
import { usePreRegisteredInit } from './PreRegisteredData';
import { PreRegistered } from './PreRegistered';

const PreRegisteredList: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { preRegistereds } = usePreRegisteredInit();
  const [preRegisteredList, setPreRegisteredList] = useState<PreRegistered[]>();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
        field: 'number',
        headerName: 'number',
        width: 200,
    }, 
    {
        field: 'date',
        headerName: 'Date',
        width: 250,
        renderCell: (params: GridRenderCellParams) => {
            var viewDate:any = params.row.date;
            var date = new Date(1970, 0, 1);
            if(viewDate && viewDate.seconds){
              date.setSeconds(viewDate.seconds);
            }
            return <Tooltip title={date.toDateString()}><p>{date.toDateString()}</p></Tooltip>;
          }
    },
  ]

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = preRegistereds?.filter((preRegistered) => {
      return preRegistered?.number?.toLowerCase().includes(lowercasedInput);
    });
    setPreRegisteredList(results);
  }

  return (
    <IonPage>
         <SideMenu3 title="Pre Registered Users" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {preRegisteredList ? <DataGrid
                    rows={preRegisteredList}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  /> : preRegistereds && preRegistereds.length > 0 ? 
                  <DataGrid
                    rows={preRegistereds}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  />
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>
          </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: { 
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default PreRegisteredList;