import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { Grid, makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useContext, useEffect, useState } from 'react';
import swal from 'sweetalert';
import SideMenu3 from '../../components/menu/SideMenu3';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';
import NotificationService from '../../services/NotificationService';
import { Order } from './Order';
import { useOrderInit } from './OrderDataStatus';
import OrderService from './OrderService';

const OrderListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  let type = new URLSearchParams(window.location.search).get("type");
  console.log(type);
  const s = new URLSearchParams(window.location.search).get("from");
  const t = new URLSearchParams(window.location.search).get("till");
  console.log(s+" "+t);
  let start:Date = new Date(s ? parseInt(s) : 1636621412027);
  let till:Date = new Date(t ? parseInt(t) : 1636621412027);
  const { orders } = useOrderInit(type ? type:'', start, till);
  const [orderList, setOrderList] = useState(orders);
  const [currentPage, setCurrentPage] = useState(1);
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  const[newDate, setNewDate] = useState<any>();
  useEffect(()=>{
    if(!newDate){
      GlobalService.getLiveDate().then((res)=>{
        if(res){
          setNewDate(res);
          // console.log(newDate)
        }
      })
    }
  })

  const viewOrder = (key:any) => {
    navigate("/order?key="+key,"forward","push");
  }

  
  const handleChangePage = useCallback(
    (gridPageChangeParams)=> setCurrentPage(gridPageChangeParams.page),
     []
   );

  const approveClick = (item:Order) => {
    swal({
      title: item.id ? item.id+'' : 'Alert',
      text: item.status === 'pending' ? "Are you sure that you want to approve this order ?" : "Are you sure that you want to change this order to pending ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(item.status === 'pending'){
          item.status = "approved";
        }else{
          item.status = "pending";
        }
        let rs = OrderService.addOrderData(item);
        rs.then((response)=>{
          console.log(response);
          if(response){
            // Update inventory for each product
           swal(item.status ? item.status : 'Status', "Order has been "+item.status+"!", "success");
           NotificationService.sendNotificationForOrderStatus(item.key ? item.key : '');
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        });
        
      }
    });
  }

  const cancelClick = (item:Order) => {
    swal({
      title: item.id ? item.voucherNumber+'' : 'Alert',
      text: item.status != 'cancelled' ? "Are you sure that you want to cancel this order ?" : "Are you sure that you want to change this order to pending state ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(item.status != 'cancelled'){
          item.status = "cancelled";
        }else{
          item.status = "pending";
        }
        let rs = OrderService.addOrderData(item);
        rs.then((response)=>{
          console.log(response);
          if(response){
            // Update inventory for each product
           swal(item.status ? item.status : 'Status', "Order has been "+item.status+"!", "success");
           NotificationService.sendNotificationForOrderStatus(item.key ? item.key : '');
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        });
        
      }
    });
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 60,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
    },
    {
        field: 'timestamp',
        headerName: 'Date',
        hideSortIcons: true,
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
          if(params.row.date){
            return <Tooltip title={GlobalService.getIndianDateFormatFromDate(params.row.date)}><p>{GlobalService.getIndianDateFormatFromDate(params.row.date)}</p></Tooltip>
          }
        },
    },
    {
      field: 'voucherNumber',
      headerName: 'Order no. 21-22',
      hideSortIcons: true,
      width: 80,
  }, 
    {
        field: 'total',
        headerName: 'Amount',
        hideSortIcons: true,
        width: 120,
    }, 
    {
        field: 'supplier',
        headerName: 'Supplier',
        hideSortIcons: true,
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
            return <Tooltip title={params.row.supplier.name}><p className='supplier'>{params.row.supplier.name}</p></Tooltip>
        },
    },
    {
      field: 'supplierFirm',
      headerName: 'Supplier Firm',
      hideSortIcons: true,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
          return <Tooltip title={params.row.supplier.firmName}><p className='supplier'>{params.row.supplier.firmName}</p></Tooltip>
      },
  },
    {
      field: 'buyer',
      headerName: 'Customer',
      hideSortIcons: true,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
          return <Tooltip title={params.row.user.name+' | '+params.row.user.userId}><p className='customer'>{params.row.user.name} | {params.row.user.userId}</p></Tooltip>
      },
    },  
    {
      field: 'buyerFirm',
      headerName: 'Customer Firm',
      hideSortIcons: true,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
          return <Tooltip title={params.row.user.firmName}><p className='customer'>{params.row.user.firmName}</p></Tooltip>
      },
    },  
    {
        field: 'status',
        headerName: 'Status',
        hideSortIcons: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return <p style={{color: params.row.status=='approved' ? 'var(--ion-color-success)' : 'var(--ion-color-danger)'}}><b>{params.row.status}</b></p>
        }
    },  
      
      {
        field: 'supplierStatus',
        headerName: 'Sup. Status',
        hideSortIcons: true,
        width: 100,
    },  
    {
        field: 'view',
        headerName: 'View',
        hideSortIcons: true,
        width: 90,
        renderCell: (params: GridRenderCellParams) => {
            return <a onClick={()=>{viewOrder(params.row.key)}}><b>View</b></a>
        },
    },
    {
      field: 'approve',
      headerName: 'Approve',
      hideSortIcons: true,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
          return <a onClick={()=>{approveClick(params.row)}}><b style={{color: params.row.status=='approved' ? 'var(--ion-color-danger)' : 'var(--ion-color-success)'}}>{params.row.status === 'pending' ? 'Approve' : 'Pending'}</b></a>
      },
    },
    {
        field: 'cancel',
        headerName: 'Cancel',
        width: 100,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => {
          // return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>{cancelClick(params.row)}}>{params.row.status === 'cancelled' ? 'Cancelled' : 'Cancel'}</Button>
            return <a onClick={()=>{cancelClick(params.row)}}><b>{params.row.status === 'cancelled' ? 'Cancelled' : 'Cancel'}</b></a>
        },
    },
    {
      field: 'PDF',
      headerName: 'PDF',
      width: 100,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
          return <a onClick={()=>{OrderService.printPdf(params.row)}}><b>PDF</b></a>
      },
  },
  ]

  const searchevent = (e:any) => { 
    console.log(e.detail.value);
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = orders?.filter((order) => {
      return order?.status?.toLowerCase().includes(lowercasedInput) || order?.user?.name?.toLowerCase().includes(lowercasedInput) || order?.supplier?.name?.toLowerCase().includes(lowercasedInput) || order?.user?.userId?.toLowerCase().includes(lowercasedInput);
      // Use the toLowerCase() method to make it case-insensitive
    });
    setOrderList(results);
  }

  return (
    <IonPage>
         <SideMenu3 title={type ? type + ' Orders' : 'Orders'} searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {orderList ? <DataGrid
                    rows={orderList}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                    onPageChange={handleChangePage}
                  /> : orders && orders.length > 0 ? 
                  <DataGrid
                    rows={orders}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                    onPageChange={handleChangePage}
                  />
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>
          </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: { 
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default OrderListPage;