import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Color } from './Color';

interface Colors{
    colors?:Color[];
}

export function useColorInit():Colors {
  const [colors, setColors] = useState<Colors>({colors: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("color").doc("color")
        .onSnapshot((querySnapshot) => {
            var data:Color[] = [];
            if(querySnapshot.exists){
                var obj:any = querySnapshot.data();
                if(obj.color && obj.color.length>0){
                    var cs:Color[] = obj.color;
                    var count = 1;
                    for(var i=0; i<cs.length; i++){
                        cs[i].id = count;
                        count++;
                    }
                    data = cs;
                }
            }
            var sorted = data.slice(0);
            sorted.sort(function(a,b) {
                var x = a.name;
                var y = b.name;
                if(x && y){
                    return x < y ? -1 : x > y ? 1 : 0;
                }else{
                    return 0;
                }
            });
            if(sorted && sorted.length>0){
                for(var i=0; i < sorted.length; i++){
                    sorted[i].id = i+1;
                }
            }
            var us:Colors = {colors: sorted};
            setColors(us);
        });

        // Unsubscribe from events when no longer in use
        return subscriber;
  }, []);

  return colors;
}
