import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography
  } from '@material-ui/core';
  import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
  import MoneyIcon from '@material-ui/icons/Money';
  import { red } from '@material-ui/core/colors';
  import CloseIcon from '@mui/icons-material/Close';
  import EditIcon from '@mui/icons-material/Edit';
  
  const PrimaryCard = ({text, count, icon, iconSize, color, backgroundColor,image, page, textColor, cardClick, iconClick, editClick}:any) => (
    
    <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
    <Card
   
    style={{ height: '100%', marginTop: '10px', width: '220px', backgroundColor: backgroundColor, color: color }}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item xs={8} onClick={cardClick}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              <a>{text}</a>
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{color: textColor}}
            >
              <b>{count}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <IconButton onClick={iconClick} color="secondary" aria-label="Remove">
                {icon}
            </IconButton>
            <IconButton onClick={editClick} color="secondary" aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          style={{
           
            display: 'flex',
            alignItems: 'center'
          }}
        >
        </Box>
      </CardContent>
    </Card>
    </Grid>
  );

  PrimaryCard.defaultProps = {
    backgroundColor: 'var(--ion-color-primary-white)',
    color: 'var(--ion-color-primary-black)',
    iconSize: 56,
    icon: <CloseIcon />,
    text: 'sample',
    count: '0',
    page: "Home",
}
  
  export default PrimaryCard;
  