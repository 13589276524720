import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";

function GetSize(){
    
}

export async function addSizeData(size:any) {
        var response = false;
        await db.collection("size").doc("size").set({
            size
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getSizeData() {
    var size: any;
    
    await db.collection("size").doc("size")
    .get()
    .then((doc) =>{
        size = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await size;
}


export default { 
    GetSize,
    addSizeData,
    getSizeData,
}