import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Unit } from './Unit';

interface Units{
    units?:Unit[];
}

export function useUnitInit():Units {
  const [units, setUnits] = useState<Units>({units: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("unit").doc("unit")
        .onSnapshot((querySnapshot) => {
            var data:Unit[] = [];
            if(querySnapshot.exists){
                var obj = querySnapshot.data();
                data = obj && obj.unit ? obj.unit : [];
            }
            var us:Units = {units: data};
            setUnits(us);
        });

        // Unsubscribe from events when no longer in use
        return subscriber;
  }, []);

  return units;
}
