import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app'; import 'firebase/auth';
import { User } from './User';

interface Users {
  users?: User[];
}

export function useUserSelectInit(): Users {
  const [users, setusers] = useState<Users>({ users: undefined }); // Initial empty array of users 
  useEffect(() => {
    var uid: any = "";
    if (firebase.auth().currentUser) {
      uid = firebase.auth().currentUser?.uid;
    }
    const subscriber = db.collection("users").where("user.role","==","Supplier").where("user.status","==","active")
      .onSnapshot((querySnapshot) => {
        const data: User[] = [];
        var c = 1;  
        querySnapshot.forEach((doc) => {
          var res = doc.data();
          var obj = res.user;
          obj.sn = c;
          c++;
          data.push(obj);
        });

        const obj = { users: data };
        setusers(obj);
      });

    // Unsubscribe from events when no longer in use
    return subscriber;
  }, []);

  return users;
}
