import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import PatternService from './PatternService';
import { usePatternInit } from './PatternData';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Pattern } from './Pattern';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';

const PatternListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { patterns } = usePatternInit();
  const [patternList, setPatternList] = useState<Pattern[]>();
  let { height, width } = useWindowDimensions();

  const removeClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this pattern ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete) {
        if(patterns){
          var newPatterns = patterns.filter(function( obj ) {
              return obj.name !== item.name;
          });
          var u = await PatternService.addPatternData(newPatterns);
          if(u){
            swal("Success !", "Pattern removed successfully!", "success");
          }
        }
      }
    });
  }

  const addPattern = () => {
    const value = swal({
      text: 'Enter Pattern',
      content: {
        element: "input",
        attributes: {
          placeholder: 'Denim',
          required: true,
        }
      },
      buttons: ["cancel","confirm"]
    });
    value.then(async (pattern) => {
      if(pattern){
          var val:string = GlobalService.capitalizeCamelCase(pattern);
          var uts:Pattern[] = [];
          if (patterns && patterns.length > 0){
            if(patterns.some(e => e.name === val)){
              GlobalService.showtoast(val, "Already exist", "error", undefined, undefined);
              return;
            }else{
              if(patterns.length>0){
                uts = patterns.map(obj => ({...obj}));
              }
              var id:any = patterns[patterns.length-1] && patterns[patterns.length-1].id ? patterns[patterns.length-1].id : 0;
              id = parseInt(id)+1;
              var u:Pattern = {name: val, id: id};
              uts.push(u);
            }
          }else{
            var u:Pattern = {name: val, id: 1};
            uts.push(u);
          } 
          let res = await PatternService.addPatternData(uts && uts.length>0 ? uts : patterns);
          if(res){
            swal("Success !", "Pattern added successfully!", "success");
          }
      }
    });
  }

  const editClick = (key:any) => {
    navigate("/addPattern?key="+key,"forward","push");
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = patterns?.filter((pattern) => {
      return pattern?.name?.toLowerCase().includes(lowercasedInput);
    });
    setPatternList(results);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return <Button color="secondary"  variant="contained" onClick={() => {removeClick(params.row)}}>Remove</Button>;
      }
    },
  ];

  return (
    <IonPage>
         <SideMenu3 title="Pattern" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
                {patternList || patterns ? <div style={{ height: height * 88 / 100, width: '100%' }}>
                    <DataGrid
                    rows={patternList ? patternList : patterns ? patterns : []}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                  /> 
                </div> : ''}
            </Grid>
          </div>
          <CustomFab onclick={addPattern} />     
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default PatternListPage;