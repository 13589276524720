import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";
import GlobalService from '../../services/GlobalService';
import { Marketing } from './Marketing';

function GetMarketing(){
    
}

export async function addMarketingData(marketing:Marketing) {
        var response = false;
        if(!marketing.status){
            marketing.status = 'active';
        }
        const timestamp = GlobalService.getCurrentDate().getTime();
        var response = false;
        var count = false;
        if(!marketing.id){
            marketing.id = "k"+timestamp;
            count = true;
        }
        // get voucher number
        marketing.updatetimestamp = GlobalService.getCurrentDate().toDateString();
        if(!marketing.timestamp){
            marketing.timestamp = GlobalService.getCurrentDate().toLocaleString();
        }
        await db.collection("marketings").doc(marketing.id).set({
            marketing
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function addMarketingStatusData(marketing:any) {
    var response = false;
    var data = {status: marketing.status, role: marketing.role};
    await db.collection("marketings").doc(marketing.id).collection("organization").doc("private").set({
        data
    })
    .then(() => {
        console.log("Document successfully written!");
        // if(count){
        //     CounterTableService.addCountTableData("marketingCount","auto",undefined,uid);
        // }
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}

export async function getMarketingData(id:any) {
    var marketing: any;
    
    await db.collection("marketings").doc(id)
    .get()
    .then((doc) =>{
        marketing = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await marketing;
}

export async function getMarketingDataByFilter(name:string, value: string) {
    var list:Marketing[] =  [];
    await db.collection("marketings").where("marketing."+name,"==",value)
    .get()
    .then((querySnapshot) =>{
        querySnapshot.forEach((doc)=>{
            list.push(doc.data().marketing);
        })
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await list;
}

export async function removeMarketingData(key:any) {
    var response = false;
    console.log(key);
    await db.collection("marketings").doc(key).delete()
    .then(()=>{
        // CounterTableService.addCountTableData("marketingCount","minus",undefined,uid);
        response = true;
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return response;
}

export default { 
    GetMarketing,
    addMarketingData,
    getMarketingData,
    removeMarketingData,
    addMarketingStatusData,
    getMarketingDataByFilter,
}