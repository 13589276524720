
import './DashboardPage.css'
import MainLayout from '../../components/layout/MainLayout';
import DashCard from '../../components/Card/DashCard';
import cards from './CardsList';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { IonPage, NavContext } from '@ionic/react';
import { useContext } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import CustomFab from '../../components/fab/CustomFab';
import { useCounterInit } from '../../hooks/CounterHook';

const DashboardPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const {counters} = useCounterInit();
  const tagItems = cards.list.map((item, index) =>{
    switch(item.label){
      case "facility":
        item.count = counters && counters.facilityCount ? counters.facilityCount+"" : '0';
        break;
      case "vendor":
        item.count = counters && counters.vendorCount ? counters.vendorCount+"" : '0';
        break;
      case "customer":
        item.count = counters && counters.customerCount ? counters.customerCount+"" : '0';
        break;    
      case "manufacturer":
        item.count = counters && counters.manufacturerCount ? counters.manufacturerCount+"" : '0';
        break;  
      case "product":
        item.count = counters && counters.productCount ? counters.productCount+"" : '0';
        break; 
      case "godown":
        item.count = counters && counters.godownCount ? counters.godownCount+"" : '0';
        break; 
      case "fabricator":
        item.count = counters && counters.fabricatorCount ? counters.fabricatorCount+"" : '0';
        break;   
      case "purchaseOrder":
        item.count = counters && counters.purchaseOrderCount ? counters.purchaseOrderCount+"" : '0';
        break;   
      case "creditNote":
        item.count = counters && counters.creditNoteCount ? counters.creditNoteCount+"" : '0';
        break;            
      case "stockInput":

        break;    
      case "stockOutput":
        
        break;  
      case "inputRegister":
        
        break;    
      case "outputRegister":
        
        break;  
      case "inventory":
       
        break;
      case "invoice":
        item.count = counters && counters.invoiceCount ? counters.invoiceCount+"" : '0';
        break;    
      case "activeinventory":
        item.count = counters && counters.activeinventoryCount ? counters.activeinventoryCount+"" : '0';
        break;    
    }
    return (
        <DashCard key={index} text={item.text} textColor={item.textColor} count={item.count} image={item.image} color={item.color} backgroundColor={item.backgroundColor}  iconSize={item.iconSize} icon={item.icon} page={item.page} onclick = {()=>{navigate(item.page,"forward","push")}} />
    );     
});

const addBlutooth = () =>{
  console.log("start");
  // BluetoothService.GetAnyDeviceAsync();
}

  return (
    <IonPage>
         <SideMenu3 title="Dashboard" />
          <div style={{marginTop: '60px', marginLeft: '60px', padding: '10px', paddingBottom: '20px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
          <Container maxWidth={false}>
                    <Grid
                      container
                      spacing={3}
                    >
                  {tagItems}
                  </Grid>
                  </Container>
          </div>
          
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default DashboardPage;