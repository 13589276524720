import { Button, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { IonBadge, IonPage, IonToggle, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import MarketingService from './MarketingService';
import { useMarketingInit } from './MarketingData';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Marketing } from './Marketing';
import CustomLoading from '../../components/loading/CustomLoading';
import CustomFab from '../../components/fab/CustomFab';

const MarketingListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { marketings } = useMarketingInit();
  const [marketingList, setMarketingList] = useState<Marketing[]>();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;

  const approveClick = (async (item:any) => {
    var sts = item.status === 'active' ? 'inactive' : 'active';
    swal({
      title: item.name,
      text: "Are you sure that you want to "+sts+" this account ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willApprove) => {
      if (willApprove) {
        item.status = sts;
        let rs = await MarketingService.addMarketingData(item);
        if(rs){
            swal("Approved!", "Account has been "+sts+"!", "success");
        }else{
          swal("Failed!", "Something is not correct!", "failed");
        }
      }
    });
  });

  const removeClick = (async (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this account ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willApprove) => {
      if (willApprove) {
        let rs = await MarketingService.removeMarketingData(item.id);
        if(rs){
            swal("Approved!", "Account has been removed.", "success");
        }else{
          swal("Failed!", "Something is not correct!", "failed");
        }
      }
    });
  });

  const columns: GridColDef[] = [
    {
      field: 'sn',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title=''><a onClick={()=>{navigate("/marketingProfile?key="+params.row.id,"forward","push")}}>{params.row.name}</a></Tooltip>;
      }
    },
    {
      field: 'firmName',
      headerName: 'Title',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.firmName ? params.row.firmName : ''}><p>{params.row.firmName ? params.row.firmName : ''}</p></Tooltip>;
      },
    },
    {
      field: 'number',
      headerName: 'Phone',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.number ? params.row.number : ''}><p>{params.row.number ? params.row.number : ''}</p></Tooltip>;
      },
    },
    {
      field: 'email',
      headerName: 'email',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.email ? params.row.email : ''}><p>{params.row.email ? params.row.email : ''}</p></Tooltip>;
      }
    },
    {
      field: 'city',
      headerName: 'City',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.city ? params.row.city : ''}><p>{params.row.city ? params.row.city : ''}</p></Tooltip>;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.status}><p>{params.row.status}</p></Tooltip>;
      }
    },
    {
      field: 'reff',
      headerName: 'Code',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.referralCode}><p>{params.row.referralCode}</p></Tooltip>;
      }
    },
    {
      field: 'city',
      headerName: 'city',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.address.city}><p>{params.row.address.city}</p></Tooltip>;
      },
    },
    {
      field: 'approval',
      headerName: 'Approval',
      width: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>approveClick(params.row)}>{params.row.status=='active' ? 'inactive' : 'active'}</Button>;
      }
    },
      {
        field: 'remove',
        headerName: 'Remove',
        width: 120,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return <Button disabled={params.row.status === 'active' ? true : false} style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>removeClick(params.row)}>Remove</Button>;
        }
    },
  ]

  const searchevent = (e:any) => { 
    console.log(e.detail.value);
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = marketings?.filter((marketing) => {
      return marketing?.name?.toLowerCase().includes(lowercasedInput) || marketing?.firmName?.toLowerCase().includes(lowercasedInput) || marketing?.email?.toLowerCase().includes(lowercasedInput) || marketing?.gst?.toLowerCase().includes(lowercasedInput) || marketing?.role?.toLowerCase().includes(lowercasedInput) || marketing?.status?.toLowerCase().includes(lowercasedInput) || marketing?.address?.city?.toLowerCase().includes(lowercasedInput) || marketing?.address?.local?.toLowerCase().includes(lowercasedInput) || marketing?.number?.toLowerCase().includes(lowercasedInput);
    });
    setMarketingList(results);
  }

  return (
    <IonPage>
      {marketings  ? '' : <CustomLoading />}
         <SideMenu3 title="Marketing" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {marketingList ? <DataGrid
                    rows={marketingList}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  />  : marketings && marketings.length>0 ? 
                    <DataGrid
                    rows={marketings}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  /> 
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>
          </div>
          <CustomFab onclick={()=>{
          navigate("/marketingProfile","forward","push");
        }} />     
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default MarketingListPage;