import React from 'react'

function checkEmail(val:string){
    var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return pattern.test(val);
}

function checkPassword(val:string){
    var pattern = /^(?=.*\d).{8,}$/;
    return pattern.test(val);
}

function checkNumber(val:any){
    var pattern = /^[2-9]{1}[0-9]{9}$/;
    return pattern.test(val) && val.length === 10;
}

function checkOtp(val:any){ 
    var pattern = /^[0-9]{6}$/;
    return pattern.test(val) && val.length === 6;
}

function checkName(val:string){
    var pattern = /^[a-zA-Z .]{2,}$/;
    return pattern.test(val);
}

function checkGst(val:string){
    var pattern = /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/;
    return pattern.test(val);
}

function checkAdhaar(val:string){
    var pattern = /^[2-9]{1}[0-9]{11}$/;
    return pattern.test(val);
}

function checkPin(val:any){ 
    var pattern = /\d/g;
    return pattern.test(val) && val.length === 6;
}

export default { 
    checkEmail,
    checkPassword,
    checkNumber,
    checkName,
    checkOtp,
    checkGst,
    checkPin,
    checkAdhaar,
}
