import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';import 'firebase/auth';
import { Order } from './Order';
import { db } from '../../App';
import GlobalService from '../../services/GlobalService';

interface Orders{
    order?:Order;
}

export function useOrderKeyInit(key: string):Orders {
  const [orders, setOrders] = useState<Orders>({}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
      const subscriber = db.collection("orders").doc(key)
      .onSnapshot((doc) => {
          var o:any = doc.data();
          if(o && o.order){
            const ord:Order = o.order;  
            const obj = { order: ord };
            setOrders(obj);
          }
      });
      // Unsubscribe from events when no longer in use
      return subscriber;
  }, []);

  return orders;
}
