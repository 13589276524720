import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";

function GetCategory(){
    
}

export async function addCategoryData(category:any) {
        var response = false;
        await db.collection("category").doc("category").set({
            category
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return await response;
}

export async function getCategoryData() {
    var category: any;
    await db.collection("category").doc("category")
    .get()
    .then((doc) =>{
        category = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await category.category;
}

export default { 
    GetCategory,
    addCategoryData,
    getCategoryData,
}