import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import { Product } from "../product/Product";
import settings from '../../config/settings';
import GlobalService from '../../services/GlobalService';

function GetProduct(){
    
}

export async function addProductData(product:Product, uid:any) { 
        let timestamp = GlobalService.getCurrentDate().getTime();
        var response = false;
        product.updatetimestamp = GlobalService.getCurrentDate().toDateString();
        if(!product.timestamp){
            product.timestamp = GlobalService.getCurrentDate().toLocaleString();
        }
        var products:Product[] = await getUserProducts(uid);
        if(!product.key){
            product.key = "k"+timestamp; 
            products?.push(product);
        }else{
            products = products.filter(function( obj ) {
                return obj.key !== product.key;
            });
            products.push(product);
        }
       
        await db.collection("product").doc(uid).set({
            products,
            status: "active"
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function updateProductData(products:Product[], uid:string) { 
    var response = false;
    await db.collection("product").doc(uid).set({
        products,
        status: "active" 
    })
    .then(() => {
        console.log("Document successfully written!");
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}

export async function updateProductDataStatus(uid:string, status:string) { 
    var response = false;
    var products:Product[] = await getUserProducts(uid);
    await db.collection("product").doc(uid).set({
        products,
        status: status
    })
    .then(() => {
        console.log("Document successfully written!");
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}

export function getProductUrl(product:Product){
    if(product.image){
        var url = settings.firestoreUrlPrefix+product.image.replaceAll("/","%2F")+settings.firestoreUrlSuffix;
        return url;
    }else{
        return '';
    }
    
}

export async function getUserProducts(uid:string){
    var products: Product[] = [];
    if(uid){
        await db.collection("product").doc(uid)
        .get()
        .then((doc) =>{
            var p:any = doc.data();
            for(var prod of p.products){
                products.push(prod);
            }
        }).catch((err)=>{
           // CommonErrorHandlar.catcherror(err);
        });
    }
    return products;
}

export default { 
    GetProduct,
    addProductData,
    getProductUrl,
    getUserProducts,
    updateProductData,
    updateProductDataStatus,
}