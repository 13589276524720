import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
);

export default function ManufacturerCard({manufacturer, removeClick, editClick}:any) {
  const classes = useStyles();
  var manufacturerChar = manufacturer.tradeName.substring(0,1);
  return (
    <div className={classes.root}>
      <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {manufacturerChar}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            { manufacturer.status? <CheckCircleIcon style={{ color: green[900] }} /> : <CancelIcon style={{ color: red[900] }} />}
          </IconButton>
        }
        title={manufacturer.tradeName}
        subheader={manufacturer.updatetimestamp? manufacturer.updatetimestamp: 'Time'} 
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {manufacturer.name} {manufacturer.contact? " | "+manufacturer.contact : ''} 
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {manufacturer.address}
        </Typography>
        {manufacturer.description? <Typography variant="body2" color="textSecondary" component="p">
          {manufacturer.description}
        </Typography> : ''}
      </CardContent>
      <CardActions disableSpacing>
        <Typography gutterBottom variant="body2">
            <Button style={{backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast)', marginLeft: '5px', marginRight: '5px'}} onClick={removeClick}>Remove</Button>
            <Button style={{backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast)', marginLeft: '5px', marginRight: '5px'}} onClick={editClick}>Edit</Button>
        </Typography>
      </CardActions>
    </Card>
    </div>
  );
}