import { Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import CategoryService from './CategoryService';
import { useCategoryInit } from './CategoryData';
import CategoryCard from './CategoryCard';
import GlobalService from '../../services/GlobalService';
import { PrimaryCategory } from './PrimaryCategory';
import { SubCategory } from './SubCategory';
import { Sub2Category } from './Sub2Category';

const Sub2CategoryListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  let name = new URLSearchParams(window.location.search).get("name");
  let sub = new URLSearchParams(window.location.search).get("sub");
  if(name && sub){
    name = decodeURI(name).replace("11nd", "&");
    sub = decodeURI(sub).replace("11nd", "&");
  }
  const { categories } = useCategoryInit();
  const [subCategory, setSubCategory] = useState<PrimaryCategory>();
  const [category, setCategory] = useState<SubCategory>();
  const [subCategoryList, setSubCategoryList] = useState<Sub2Category[]>([]);

  useEffect(() => {
    if(!category){
      const categorys = CategoryService.getCategoryData();
      categorys.then((cats:PrimaryCategory[])=>{
        if(cats && cats.length>0){
          const res = cats.filter(obj => {
            return obj.name === name
          });
          if(res && res[0]){
              setSubCategory(res[0]);
            if(res[0].subCategories){
                const ress = res[0].subCategories.filter(obj => {
                    return obj.name === sub
                });
                if(ress && ress[0]){
                    setCategory(ress[0]);
                    if(ress[0].sub2Categories){
                        var sorted = ress[0].sub2Categories.slice(0);
                        sorted.sort(function(a,b) {
                            var x = a.name;
                            var y = b.name;
                            if(x && y){
                                return x < y ? -1 : x > y ? 1 : 0;
                            }else{
                                return 0;
                            }
                        });
                        setSubCategoryList(sorted);
                    }
                }else{
                    navigate("/category","back","pop");
                }
            }
          }else{
            navigate("/category","back","pop");
          }
        }
        return ;
      }).catch((err)=>{
        return ;
      })
    }else{
      return ;
    }
  }, []);

  const iconClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this sub category, All the sub categories of this category will be deleted as well ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(subCategoryList && category && subCategory && categories){
            if (categories.some(e => e.name === subCategory.name)) {
                if (subCategory.subCategories && subCategory.subCategories.some(e => e.name === category.name)) {
                    if(category.sub2Categories){
                        var newSubCategories = subCategoryList.filter(function( obj ) {
                            return obj.name !== item.name;
                        });
                        category.sub2Categories = newSubCategories;
                        updateCategory("removed");
                    }
                }else{
                    swal("Failed !", "Primary Category already deleted!", "error");
                    navigate("/category","back","pop");
                }
            }else{
              swal("Failed !", "Primary Category already deleted!", "error");
              navigate("/category","back","pop");
            }
        }
      }
    });
  }

  const updateCategory = (status:string) =>{
    if(subCategoryList && category && subCategory && subCategory.subCategories && categories){
        var newSubCategories = subCategory.subCategories.filter(function( obj ) {
            return obj.name !== category.name;
        });
        newSubCategories.push(category);
        var newCategories = categories.filter(function( obj ) {
            return obj.name !== subCategory.name;
        });
        var pc:PrimaryCategory = {name: subCategory.name, subCategories: newSubCategories, key: subCategory.key}
        if(subCategory.icon){
          pc.icon = subCategory.icon;
        }
        if(subCategory.image){
          pc.image = subCategory.image;
        }
        newCategories.push(pc);
        var cat = CategoryService.addCategoryData(newCategories);
        cat.then((res)=>{
            swal("Success !", "Category "+status+" successfully!", "success");
            setCategory(category);
            if(category.sub2Categories && category.sub2Categories.length>0){
            var sorted = category.sub2Categories.slice(0);
            sorted.sort(function(a,b) {
                var x = a.name;
                var y = b.name;
                if(x && y){
                    return x < y ? -1 : x > y ? 1 : 0;
                }else{
                    return 0;
                }
            });
            setSubCategoryList(sorted);
          }else{
            setSubCategoryList([]);
          }
        });
    }
  }

  const addCategory = () => {
    const value = swal({
        text: 'Enter Sub Category Name For '+category?.name,
        content: {
          element: "input",
          attributes: {
            placeholder: 'Footwear',
            required: true,
          }
        },
        buttons: ["cancel","confirm"]
      });
      value.then((categoryRes):any =>{
        if(categoryRes){
            var val:string = GlobalService.capitalizeCamelCase(categoryRes);
            if(category && categories && subCategory){
              if (categories.some(e => e.name === subCategory.name)) {
                if (subCategory.subCategories && subCategory.subCategories.some(e => e.name === category.name)) {
                    if(category.sub2Categories){
                        if (category.sub2Categories.some(e => e.name === val)) {
                            swal("Failed !", "Category already exist!", "error");
                            return;
                        }else{
                            var sc:Sub2Category = {name: val};
                            category.sub2Categories.push(sc);
                            updateCategory("added");
                        }
                    }
                }
              }else{
                swal("Failed !", "Primary Category already deleted!", "error");
                navigate("/category","back","pop");
              }
            }
        }
      });
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = category?.sub2Categories?.filter((category) => {
      return category.name.toLowerCase().includes(lowercasedInput);
    });
    if(results){
      setSubCategoryList(results);
    }
  }

  return (
    <IonPage>
         <SideMenu3 title="Main Category" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container spacing={1}>
              {subCategoryList && subCategoryList.length>0 ?
                 subCategoryList?.map((item, index) =>{
                  if(item){
                      return (
                        <Grid item key={item.name} lg={3} sm={6} xs={12} >
                          <CategoryCard text={item.name} iconClick={()=>{iconClick(item)}} count="" />
                          </Grid>
                      );     
                    }
                }) : <IonBadge color="primary">No records available</IonBadge>            
              }
            </Grid>
          </div>
        <CustomFab onclick={addCategory} /> 
              {/* <TableView /> */}
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default Sub2CategoryListPage;