import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export default { 
     list: [
        {
            name: "Dashboard",
            list:[
                {
                    key: 'K1',   
                    text: "Dashboard",
                    icon: <DashboardIcon style={{fontSize: '24px',}} />,
                    count: undefined,
                    image: '../../assets/icons/home.png',
                    backgroundColor:  'var(--ion-color-primary)',
                    color: 'var(--ion-color-primary-contrast)',
                    iconSize: 32,
                    page: "/",
                },
                {
                  key: 'K2',   
                  text: "Banners",
                  icon: <AddPhotoAlternateIcon style={{fontSize: '24px',}} />,
                  count: undefined,
                  image: '../../assets/icons/home.png',
                  backgroundColor:  'var(--ion-color-primary)',
                  color: 'var(--ion-color-primary-contrast)',
                  iconSize: 32,
                  page: "/banner",
              },
              {
                key: 'K3',   
                text: "Categories",
                icon: <CategoryIcon style={{fontSize: '24px',}} />,
                count: undefined,
                image: '../../assets/icons/home.png',
                backgroundColor:  'var(--ion-color-primary)',
                color: 'var(--ion-color-primary-contrast)',
                iconSize: 32,
                page: "/category",
            },
            {
              key: 'K4',   
              text: "Customers",
              icon: <GroupIcon style={{fontSize: '24px',}} />,
              count: undefined,
              image: '../../assets/icons/home.png',
              backgroundColor:  'var(--ion-color-primary)',
              color: 'var(--ion-color-primary-contrast)',
              iconSize: 32,
              page: "/user",
          },
          {
            key: 'K4.1',   
            text: "Suppliers",
            icon: <GroupIcon style={{fontSize: '24px',}} />,
            count: undefined,
            image: '../../assets/icons/home.png',
            backgroundColor:  'var(--ion-color-primary)',
            color: 'var(--ion-color-primary-contrast)',
            iconSize: 32,
            page: "/supplier",
        },
          {
            key: 'K5',   
            text: "Products",
            icon: <ShoppingBasketIcon style={{fontSize: '24px',}} />,
            count: undefined,
            image: '../../assets/icons/home.png',
            backgroundColor:  'var(--ion-color-primary)',
            color: 'var(--ion-color-primary-contrast)',
            iconSize: 32,
            page: "/product",
        },
            ]
        },
    ]
}
   