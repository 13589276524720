import 'firebase/auth';
import { useEffect, useState } from 'react';
import { db } from '../../App';
import { Order } from './Order';

interface Orders{
    orders?:Order[];
}

export function useOrderInit(type:string, start: Date, till: Date):Orders {
    console.log(start)
    console.log(till)
    console.log(type)
  const [orders, setOrders] = useState<Orders>({orders: undefined}); // Initial empty array of tests 
  useEffect(() => {
    const subscriber = db.collection("orders").where("order.date",">=",start).where("order.date","<=",till)
      .onSnapshot((querySnapshot) => {
          const data:Order[] = [];
          var i = 1;
          querySnapshot.forEach((doc) => {
              var o = doc.data().order;
              o.id = i;
              i++;
              if(o.type === type){
                data.push(o);
              }
          });
          var sorted = data.slice(0);
          sorted.sort(function(a,b) {
                var x = a.voucherNumber;
                var y = b.voucherNumber;
                if(x && y){
                    return x > y ? -1 : x < y ? 1 : 0;
                }else{
                    return 0;
                }
            });
          const obj = { orders: sorted };
          setOrders(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
  }, []);

  return orders;
}
