import { Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import CategoryService from './CategoryService';
import { useCategoryInit } from './CategoryData';
import PrimaryCard from './PrimaryCard';

const CategoryListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { categories } = useCategoryInit();
  const [categoryList, setCategoryList] = useState(categories);

  const iconClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this category, All the sub categories will be deleted as well ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(categories){
            var newCategories = categories.filter(function( obj ) {
                return obj.name !== item.name;
            });
            var cat = CategoryService.addCategoryData(newCategories);
            cat.then((res)=>{
                swal("Success !", "Category removed successfully!", "success");
                setCategoryList(newCategories);
            });
        }
      }
    });
  }

  const cardClick = (item:any) => {
      var category = encodeURI(item.name).replace("&", "11nd");
    navigate("/subCategory?name="+category,"forward","push");
  }

  const addCategory = () => {
    // const value = swal({
    //     text: 'Enter Primary Category Name',
    //     content: {
    //       element: "input",
    //       attributes: {
    //         placeholder: 'Toys',
    //         required: true,
    //       }
    //     },
    //     buttons: ["cancel","confirm"]
    //   });
    //   value.then((categoryRes):any =>{
    //     if(categoryRes){
    //         console.log(categories)
    //         console.log(categoryRes);
    //         var val:string = GlobalService.capitalizeCamelCase(categoryRes);
    //         if(categories){
    //             if (categories.some(e => e.name === val)) {
    //                 /* vendors contains the element we're looking for */
    //                 swal("Failed !", "Category already exist!", "error");
    //                 return;
    //             }else{
    //                 var pc:PrimaryCategory = {name: val, subCategories: []}
    //                 categories.push(pc);
    //                 var cat = CategoryService.addCategoryData(categories);
    //                 cat.then((res)=>{
    //                     console.log(res);
    //                     swal("Success !", "Category added successfully!", "success");
    //                     setCategoryList(categories);
    //                 });
    //             } 
    //         }
    //     }
    //   });
      navigate("/addCategory","forward","push");
  }

  var tagItems = categories?.map((item, index) =>{
    if(item){
        return (
          <Grid item key={item.name} lg={3} sm={6} xs={12} >
            <PrimaryCard text={item.name} cardClick={()=>{cardClick(item)}} iconClick={()=>{iconClick(item)}} editClick={()=>{console.log("tes");navigate("/addCategory?key="+item.key,"forward","push");}} count={item && item.subCategories && item.subCategories.length ? item.subCategories.length : 0} />
            </Grid>
        );     
      }
  });

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = categories?.filter((category) => {
      return category.name.toLowerCase().includes(lowercasedInput);
    });
    setCategoryList(results);
  }

  return (
    <IonPage>
         <SideMenu3 title="Primary Category" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container spacing={1}>
              {categoryList && categoryList.length>0 ?
                 categoryList?.map((item, index) =>{
                  if(item){
                      return (
                        <Grid item key={item.name} lg={3} sm={6} xs={12} >
                          <PrimaryCard text={item.name} cardClick={()=>{cardClick(item)}} iconClick={()=>{iconClick(item)}} editClick={()=>{console.log("tes");navigate("/addCategory?key="+item.key,"forward","push");}} count={item && item.subCategories && item.subCategories.length ? item.subCategories.length : 0} />
                        </Grid>
                      );     
                    }
                }) : tagItems && tagItems?.length>0 ? tagItems : <IonBadge color="primary">No records available</IonBadge>            
              }
            </Grid>
          </div>
        <CustomFab onclick={addCategory} /> 
              {/* <TableView /> */}
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default CategoryListPage;