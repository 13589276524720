import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import BrandService from './BrandService';
import { useBrandInit } from './BrandData';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Brand } from './Brand';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';

const BrandListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { brands } = useBrandInit();
  const [brandList, setBrandList] = useState<Brand[]>();
  let { height, width } = useWindowDimensions();

  const removeClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this brand ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete) {
        if(brands){
          var newBrands = brands.filter(function( obj ) {
              return obj.name !== item.name;
          });
          var u = await BrandService.addBrandData(newBrands);
          if(u){
            swal("Success !", "Brand removed successfully!", "success");
          }
        }
      }
    });
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = brands?.filter((brand) => {
      return brand?.name?.toLowerCase().includes(lowercasedInput) || brand?.sub?.toLowerCase().includes(lowercasedInput);
    });
    setBrandList(results);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250
    },
    {
      field: 'sub',
      headerName: 'Sub Text',
      width: 250
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <img src={params.row.url} height={50} />;
      }
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <Button color="secondary"  variant="contained" onClick={() => {navigate("/addBrand?key="+params.row.key,"forward","push")}}>Edit</Button>;
      }
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <Button color="secondary"  variant="contained" onClick={() => {removeClick(params.row)}}>Remove</Button>;
      }
    },
  ];

  return (
    <IonPage>
         <SideMenu3 title="Brands" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
                {brandList || brands ? <div style={{ height: height * 88 / 100, width: '100%' }}>
                    <DataGrid
                    rows={brandList ? brandList : brands ? brands : []}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                  /> 
                </div> : ''}
            </Grid>
          </div>
          <CustomFab onclick={()=>{navigate("/addBrand","forward","push")}} />     
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default BrandListPage;