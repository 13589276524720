import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Pattern } from './Pattern';

interface Patterns{
    patterns?:Pattern[];
}

export function usePatternInit():Patterns {
  const [patterns, setPatterns] = useState<Patterns>({patterns: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("pattern").doc("pattern")
        .onSnapshot((querySnapshot) => {
            var data:Pattern[] = [];
            if(querySnapshot.exists){
                var obj = querySnapshot.data();
                data = obj && obj.pattern ? obj.pattern : [];
            }
            var us:Patterns = {patterns: data};
            setPatterns(us);
        });

        // Unsubscribe from events when no longer in use
        return subscriber;
  }, []);

  return patterns;
}
