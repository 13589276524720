import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import { User } from '../user/User';

function GetApproval(){
    
}

export async function addApprovalData(user:User, uid: string) {
        var response = false;
        await db.collection("users").doc(uid).set({ 
            user
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error:any) => {
            CommonErrorHandlar.catcherror(error);
        });
        return await response;
}

export default { 
    GetApproval,
    addApprovalData,
}