import GroupIcon from '@material-ui/icons/Group';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ExtensionIcon from '@mui/icons-material/Extension';
import InventoryIcon from '@mui/icons-material/Inventory';
import PreviewIcon from '@mui/icons-material/Preview';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export default { 
    list: [
          {
            key: '0',
            text: "Banners",
            label: "banner",
            icon: <AddPhotoAlternateIcon style={{fontSize: '56px',}}/>,
            count: '0/0',
            image: '../../assets/icons/user.png',
            backgroundColor:  'var(--ion-color-primary-white)',
            color:  'var(--ion-color-secondary)',
            textColor: 'var(--ion-color-secondary)',
            iconSize: 56,
            page: "/banner",
        },
          {
            key: '1',
            text: "Categories",
            label: "category",
            icon: <CategoryIcon style={{fontSize: '56px',}}/>,
            count: '0/0',
            image: '../../assets/icons/user.png',
            backgroundColor:  'var(--ion-color-primary-white)',
            color:  'var(--ion-color-secondary)',
            textColor: 'var(--ion-color-secondary)',
            iconSize: 56,
            page: "/category",
        },
        {
            key: '2',
            text: "Customers",
            label: "user",
            icon: <GroupIcon style={{fontSize: '56px',}}/>,
            count: '0/0',
            image: '../../assets/icons/user.png',
            backgroundColor:  'var(--ion-color-primary-white)',
            color:  'var(--ion-color-info)',
            textColor: 'var(--ion-color-info)',
            iconSize: 56,
            page: "/user",
        },
        
        {
          key: '2a',
          text: "Suppliers",
          label: "supplier",
          icon: <GroupIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/user.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-danger)',
          textColor: 'var(--ion-color-danger)',
          iconSize: 56,
          page: "/supplier",
      },
        {
          key: '3',   
          text: "Products",
          label: "product",
          icon: <ShoppingBasketIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/product",
        },
        {
          key: '4',   
          text: "Product Sizes",
          label: "size",
          icon: <AspectRatioIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/size",
        },
        {
          key: '5',   
          text: "Product Colors",
          label: "color",
          icon: <ColorLensIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/color",
        },
        // {
        //   key: '6',   
        //   text: "Product Patterns",
        //   label: "pattern",
        //   icon: <PatternIcon style={{fontSize: '56px',}}/>,
        //   count: '0/0',
        //   image: '../../assets/icons/facility.png',
        //   backgroundColor:  'var(--ion-color-primary-white)',
        //   color:  'var(--ion-color-secondary)',
        //   textColor: 'var(--ion-color-secondary)',
        //   iconSize: 56,
        //   page: "/pattern",
        // },
        {
          key: '7',   
          text: "Brands",
          label: "brand",
          icon: <ExtensionIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/brand",
        },
        {
          key: '8',   
          text: "Regular Orders",
          label: "order",
          icon: <InventoryIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/ordersFilter?type=Regular",
        },
        {
          key: '8',   
          text: "Urgent Orders",
          label: "order",
          icon: <InventoryIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/ordersFilter?type=Urgent",
        },
        {
          key: '9',   
          text: "Pre Registered",
          label: "preregistered",
          icon: <GroupIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/preregistered",
        },
        {
          key: '10',   
          text: "Customer Activity",
          label: "userProdView",
          icon: <PreviewIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-info)',
          textColor: 'var(--ion-color-info)',
          iconSize: 56,
          page: "/userProductView",
        },
        {
          key: '11',   
          text: "Supplier Activity",
          label: "supplierProdView",
          icon: <PreviewIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-danger)',
          textColor: 'var(--ion-color-danger)',
          iconSize: 56,
          page: "/supplierProductView",
        },
        {
          key: '12',   
          text: "Marketing Emp.",
          label: "supplierProdView",
          icon: <ContactPageIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/marketing",
        },
        {
          key: '13',   
          text: "Hidden Customer",
          label: "userProdView",
          icon: <FaceRetouchingOffIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-info)',
          textColor: 'var(--ion-color-info)',
          iconSize: 56,
          page: "/hideUser",
        },
        {
          key: '14',   
          text: "Hidden Supplier",
          label: "supplierProdView",
          icon: <FaceRetouchingOffIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-danger)',
          textColor: 'var(--ion-color-danger)',
          iconSize: 56,
          page: "/hideSupplier",
        },
        {
          key: '15',   
          text: "Hidden Cus. Activity",
          label: "userProdView",
          icon: <PreviewIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-info)',
          textColor: 'var(--ion-color-info)',
          iconSize: 56,
          page: "/hideUserActivity",
        },
        {
          key: '16',   
          text: "Hidden Sup. Activity",
          label: "supplierProdView",
          icon: <PreviewIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-danger)',
          textColor: 'var(--ion-color-danger)',
          iconSize: 56,
          page: "/hideSupplierActivity",
        },
        {
          key: '17',   
          text: "Notification",
          label: "notification",
          icon: <NotificationsActiveIcon style={{fontSize: '56px',}}/>,
          count: '0/0',
          image: '../../assets/icons/facility.png',
          backgroundColor:  'var(--ion-color-primary-white)',
          color:  'var(--ion-color-secondary)',
          textColor: 'var(--ion-color-secondary)',
          iconSize: 56,
          page: "/notification",
        },
      ],
}