import React, { useContext, useEffect, useState } from "react";
import firebase from 'firebase/app';import 'firebase/auth';
import { db } from "../../App";
import { Organization } from "../../interface/Organization";

interface Auth {
  loggedIn: Boolean;
  status?: string;
  role?: string;
  userId?: string;
  number?: string,
  userData?:any;
  organization?:Organization | undefined;
}
interface AuthInit {
  loading: boolean;
  auth?: Auth;
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export function useAuth(): Auth {
  return useContext(AuthContext);
}

export function UseAuthInit(): AuthInit {
  const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });
  useEffect(() => {
    const res = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
      if(firebaseUser){
        db.collection("admins").doc(firebaseUser.uid).collection("organization").doc("data")
        .onSnapshot((doc) => {
            const data = doc.data();
            if(data){
              let organization: Organization = {
                facility: data.facility,
                role: data.role,
                status: data.status,
                user: data.user,
                token: data.token,
              }
              const auth = firebaseUser
                  ? { loggedIn: true, status: data.status, role: data.role, userId: firebaseUser.uid, userData: firebaseUser, organization: organization }
                  : { loggedIn: false };
                setAuthInit({ loading: false, auth });
            }else{
              const auth = firebaseUser
                  ? { loggedIn: true, userId: firebaseUser.uid, userData: firebaseUser }
                  : { loggedIn: false };
                setAuthInit({ loading: false, auth });
            }
        });
      }else{
        const auth = { loggedIn: false };
                setAuthInit({ loading: false, auth });
      }
    });
    return res;
  }, []);
  return authInit;
}