export default {
    logo: "/assets/icon.png",
    printTop: "/assets/print-top.png",
    appname: "PKT",
    website: "https://pktbazaar.com/.com",
    APP_BASE_URL: "https://pktbazaar.com/",
    websiteShort: "pktbazaar.com",
    primaryColor: 'var(--ion-color-primary)',
    loginError: 'Please enter valid details',
    loadingIcon: "/assets/icon.png",
    imagePlaceholder: "/assets/default/image.jpg",
    imageUploadPlaceholder: "/assets/default/upload.jpg",
    billLogoAddress: "/assets/icon.png",
    maxProductStockRecord: 100,
    firestoreUrlPrefix: 'https://firebasestorage.googleapis.com/v0/b/eco-app-3ee66.appspot.com/o/',
    firestoreUrlSuffix: '?alt=media',
    billPrefix: "", 
    bankAccountNumber: "2612881572",
    ifscNumber: "KKBK0005006",
    bankName: "Kotak Mahindra",
    companyName: "P.K TRADERS", 
    companySubHeading: "Marketing of Readymade Garments",
    companyNumber: "+91 9760580786",
    whatsapp: "+91 9411651365",
    companyAddress1: "15/503, Goverdhan Market, Kali Bari Road",
    companyAddress2: "Agra",
    companyAddress3: "Agra, Uttar Pradesh - 282002, India",
    companyEmail: "PKT786@yahoo.com",
    paytm:"+91 9411651365",
    website: "www.pktbazaar.com",
    maxProductPerUser: 100,
    thumbnailSize: "_400x400",
    maxHomeScreenUser: 2,
    maxProductView: 12,
    maxBrandsView: 12,
    serverUrl: "https://asia-south1-eco-app-3ee66.cloudfunctions.net/sendHttpPushNotification",
}