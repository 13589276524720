import { NavContext } from '@ionic/react';
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { navigate } from 'ionicons/icons';
import React, { useContext } from 'react';

export default function MenuCard({data}:any) {
  const { navigate } = useContext(NavContext);

  return (
    <div onClick={()=>{navigate(data.page,"forward","push");}}>
        <ListItem button key={data.text}>
            <ListItemIcon>{data.icon}
                </ListItemIcon>
            <ListItemText primary={data.text} />
        </ListItem>
    </div>
  );
}