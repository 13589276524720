import React, { useContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ValidationPattern from '../../services/ValidationPattern';
import CustomTextInput from '../../components/common/CustomTextInput';
import { Link } from 'react-router-dom';
import validation from '../../services/ValidationPattern';
import firebaseAuth from '../../hooks/firebaseAuth';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonToggle, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import {Manufacturer} from './Manufacturer';
import manufacturerService from './ManufacturerService';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';


export default function FacilityForm({popup, popupEvent}:any) {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let key = new URLSearchParams(window.location.search).get("key");
  let uid = "";
  const { navigate } = useContext(NavContext);
  const [manufacturer, setManufacturerData] = React.useState<Manufacturer>({ 
    key: undefined,
    error: "Enter valid data", 
    errorShow: false, 
    name:'', 
    address:'', 
    tradeName: key ? 'Trade Name':'',
    contact:'',
    email:'',
    whatsapp:'',
    description:'',
    status: true,
  });

  useEffect(() => {
    const res = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
    if(firebaseUser && key){  
      uid = firebaseUser.uid;
      const subscriber = db.collection("manufacturer").doc(key? key : undefined)
      .onSnapshot((querySnapshot) => {
          if(querySnapshot.exists){
            const obj:any = querySnapshot.data();
            if(obj.manufacturer.status){
              obj.manufacturer.status = true;
            }else{
              obj.manufacturer.status = false;
            }
            setManufacturerData(obj.manufacturer);
          }
      });
    }
  });
    // Unsubscribe from events when no longer in use
    return res;
  }, []);
  
const submitForm = (e:any)=>{
  if(manufacturer.tradeName === '' ){
      manufacturer.errorShow = true;
      setManufacturerData({ ...manufacturer});
  }else{
    const fire = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
      let res = manufacturerService.addManufacturerData(manufacturer, firebaseUser.uid);
      res.then((response)=>{
        if(response){
          if(key){
            GlobalService.showtoast(manufacturer.key, "Manufacturer updated", "info", undefined, undefined);
          }else{
            if(!popup){
              navigate("/unit","back","pop");
            }
          }
          if(popup && popupEvent){
            popupEvent();
          }
        }
      });
    })
  }
}

const setData = (event:any) =>{
  const { name, value } = event.target;
  setManufacturerData({ ...manufacturer, [name]: value});
}

const setChecked = (val:Boolean) =>{
  if(manufacturer.tradeName!='Trade Name'){
    manufacturer.status = val;
    setManufacturerData({ ...manufacturer});
  }
}

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper} style={{marginTop: '0px'}}>
        
        <form className={classes.form}  noValidate>
        <Grid container justify="space-evenly">    
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <IonItem>
                        <IonLabel>Status: {manufacturer.status? 'Active': 'Disabled'}</IonLabel>
                        <IonToggle checked={manufacturer.status? true : false} onIonChange={e => setChecked(e.detail.checked)} />
                    </IonItem>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CustomTextInput error={manufacturer.errorShow && manufacturer.tradeName==''} 
                        errorText={manufacturer.error}  required={true} 
                        label="Trade Name" name="tradeName" autoComplete="tradeName" 
                        value={manufacturer.tradeName} onchange={setData} /> 
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomTextInput 
                        label="Manufacturer Name" name="name" autoComplete="name" 
                        value={manufacturer.name} onchange={setData} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CustomTextInput 
                        label="Address" name="address" autoComplete="address" 
                        value={manufacturer.address} onchange={setData} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomTextInput 
                        label="Manufacturer contact" name="contact" autoComplete="contact" 
                        value={manufacturer.contact} onchange={setData} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomTextInput 
                        label="Manufacturer email" name="email" autoComplete="email" 
                        value={manufacturer.email} onchange={setData} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomTextInput 
                        label="Manufacturer whatsapp" name="whatsapp" autoComplete="whatsapp" 
                        value={manufacturer.whatsapp} onchange={setData} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput 
                        label="Description" name="description" autoComplete="description" 
                        value={manufacturer.description} onchange={setData} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={submitForm}
            >
               {key? 'Update': 'Add'} 
            </Button>
            </Grid>
        </Grid>
        </form>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));