import CommonErrorHandlar from "./CommonErrorHandlar";
import { Camera, CameraResultType } from '@capacitor/camera';
import { fireStorage } from "../App";


function GetNativeApi(){
    
}

export async function takePicture(width:number, height:number, quality:number) {
    const image = await Camera.getPhoto({
        quality: quality>0 ? quality : 100,
		width: width>0 ? width : 1200,
		height: height>0 ? height : 600,
		preserveAspectRatio: true,
		correctOrientation: true,
		resultType: CameraResultType.Base64
      });
      var imageUrl = 'data:image/jpeg;base64,' + image.base64String;
      return await imageUrl;
}

export default { 
    GetNativeApi,
    takePicture,
}