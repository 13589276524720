import { IonPage } from '@ionic/react';
import CustomFooter from '../../components/footer/CustomFooter';
import SignupForm from './SignupForm';

import './Signup.css'

const Signup: React.FC = () => {
  
  return (
    <IonPage className="signupBackground">
      <SignupForm />
      <CustomFooter />
    </IonPage>
    
  );
};

export default Signup;