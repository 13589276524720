import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ValidationPattern from '../../services/ValidationPattern';
import CustomTextInput from '../../components/common/CustomTextInput';
import { Link } from 'react-router-dom';
import validation from '../../services/ValidationPattern';
import firebaseAuth from '../../hooks/firebaseAuth';
import { IonBadge, IonItem, IonItemGroup, IonLabel, IonListHeader, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import CustomLoading from '../../components/loading/CustomLoading';
import firebase from 'firebase';
import { db } from '../../App';
import { useCategoryInit } from '../category/CategoryData';
import Select from 'react-select';
import swal from 'sweetalert';
import LogoutButton from '../../components/common/LogoutButton';
import { useBrandInit } from '../brand/BrandData';
import { Country, State, City }  from 'country-state-city'; 
import { Marketing } from '../marketing/Marketing';
import { Category } from '../product/Category';
import MarketingService from './MarketingService';

export interface SelectOption {
  label: string,
  value: any,
} 

export default function MarketingProfileForm() {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  const { navigate } = useContext(NavContext);
  const [loading, setLoading] = useState<Boolean>();
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  let key = new URLSearchParams(window.location.search).get("key");
  const [countries, setCountries] = useState<SelectOption[]>([]);
  const [states, setStates] = useState<SelectOption[]>([]);
  const [cities, setCities] = useState<SelectOption[]>([]);

  const [logindata, setLoginData] = React.useState({
    email: '',
    emailError: 'Enter correct email address',
    password: '',
    passwordError: 'Min 8 characters and at least 1 number',
    check_textInputChange: false,
    isValidMarketing: true,
    isValidPassword: true, 
    isValidEmail: true, 
    secureTextEntry: true,
    loginError: '',
    signUp: false,
    name: '',
    nameError: 'Number and special character are not allowed',
    firmError: 'Number and special character are not allowed',
    isValidName: true,
    gst: '',
    isValidGst: true,
    gstError: 'Please enter correct aadhar number',
    number: '',
    numberError: 'Please enter correct 10 digit number',
    isValidNumber:true,
    showPassword: false,
    firmName: '',
    isValidFirmname: true,
    accountType: '',
    customerType: '',
    address: {country: {isoCode: 'IN', name: 'India'}, state: {isoCode: '', name: ''}, city: '', pin: '', local: ''},
    isValidPin: true,
    pinError: 'Please enter correct pin',
    referralCode: '',
    marketingId: '',
  });

useEffect(() => {
  const res = firebase.auth().onAuthStateChanged((firebaseMarketing:any) => {
  if(key){  
    const subscriber = db.collection("marketings").doc(key ? key : '')
    .onSnapshot((doc) => {
      const res = doc.data();
        if(res){
          const data = res.marketing;
          logindata.name = data.name ? data.name : '';
          logindata.firmName = data.firmName ? data.firmName : '';
          logindata.gst = data.gst ? data.gst : '';
          logindata.email = data.email ? data.email : '';
          logindata.referralCode = data.referralCode ? data.referralCode : '';
          logindata.marketingId = data.marketingId ? data.marketingId : '';
          logindata.number = data.number ? data.number : '';
          if(data.address){
            logindata.address = data.address;
          }
          setLoginData({...logindata});
        }
    });
  }
});
  // Unsubscribe from events when no longer in use
  return res;
}, []);

const checkEmail = (val:any) =>{
    var email = val.target.value;
    logindata.email = email;
    if(validation.checkEmail(email)){
        logindata.isValidEmail = true;
        logindata.email = email;
        setLoginData({
            ...logindata
        });
    }else{
        logindata.isValidEmail = false;
        setLoginData({ ...logindata});
    }
    
}

const checkName = (val:any) =>{
    var name = val.target.value;
    logindata.name = name ? GlobalService.capitalizeCamelCase(name) : name;
    if(validation.checkName(name)){
        logindata.isValidName = true;
        setLoginData({ ...logindata});
    }else{
        logindata.isValidName = false;
        setLoginData({ ...logindata});
    }
}

const checkFirmname = (val:any) =>{
  var name = val.target.value;
  logindata.firmName = name ? GlobalService.capitalizeCamelCase(name) : name;
  if(validation.checkName(name)){
      logindata.isValidFirmname = true;
      setLoginData({ ...logindata});
  }else{
      logindata.isValidFirmname = false;
      setLoginData({ ...logindata});
  }
}

const checkGst = (val:any) =>{
  var gst = val.target.value.toUpperCase();
  if(!gst || /^\d+$/.test(gst)){
    logindata.gst = gst;
    if(validation.checkAdhaar(gst)){
      // check if gst is unique
        logindata.isValidGst = true;
        setLoginData({ ...logindata});
    }else{
        logindata.gstError = "Please enter correct GST number";
        logindata.isValidGst = false;
        setLoginData({ ...logindata});
    }
  }
}

const checkPin = (val:any) =>{
  var pin = val.target.value;
  if(!pin || /^\d+$/.test(pin)){
    logindata.address.pin = pin;
    if(validation.checkPin(pin) ){
        logindata.isValidPin = true;
        setLoginData({ ...logindata});
    }else{
        logindata.isValidPin = false;
        setLoginData({ ...logindata});
    }
  }
}

const checkNumber = (val:any) =>{
  var number = val.target.value;
  if(!number || /^\d+$/.test(number)){
    logindata.number = number;
    if(validation.checkNumber(number) ){
        logindata.isValidNumber = true;
        setLoginData({ ...logindata});
    }else{
        logindata.isValidNumber = false;
        setLoginData({ ...logindata});
    }
  }
}

const setAddress = (val:any) =>{
  var addr = val.target.value;
  logindata.address.local = addr ? GlobalService.capitalizeCamelCase(addr) : addr;
  setLoginData({ ...logindata});
}

async function submitForm() {
  if(logindata.isValidName && logindata.name!=='' && logindata.isValidNumber && logindata.number!=='' && logindata.address.city && logindata.address.pin && logindata.address.local){
   
      var marketing:Marketing = {name: logindata.name}
      marketing.address = logindata.address;
      if(logindata.number){
          marketing.number = logindata.number;
      }
      if(logindata.email && logindata.isValidEmail && logindata.email!==''){
        marketing.email = logindata.email;
      }
      if(logindata.firmName && logindata.isValidFirmname && logindata.firmName!==''){
        marketing.firmName = logindata.firmName;
      }
      if(logindata.referralCode){
        marketing.referralCode = logindata.referralCode;
      }else{
        marketing.referralCode = GlobalService.randomString(6);
      }
      if(logindata.marketingId){
        marketing.marketingId = logindata.marketingId;
      }else{
        var ui = "";
        var rd = GlobalService.randomDigit(11,99);
        if(logindata.name.replaceAll(/\s+/g, '').length>5){
          ui = logindata.name.replaceAll(/\s+/g, '').substring(0,4);
          ui = ui + rd;
        }else{
          ui = GlobalService.randomString(4);
          ui = ui + rd;
        }
        marketing.marketingId = ui.toUpperCase();
      }
      setLoading(true);
      const res = firebase.auth().onAuthStateChanged(async (firebaseMarketing:any) => {
        if(firebaseMarketing && firebaseMarketing.uid){
          marketing.id = key ? key : '';
          console.log(marketing);
          const res = await MarketingService.addMarketingData(marketing);
          setLoading(false);
          if(res){
            if(key){
              GlobalService.showtoast('success', "User updated successfully", "info",undefined,undefined);
            }else{
              GlobalService.showtoast('success', "User added successfully", "success",undefined,undefined);
              navigate("/marketing","back","pop");
            }
          }
        }else{
          setLoading(false);
        }
      });
    
  }else{
      GlobalService.showtoast('failed', "Enter valid details", "error",undefined,undefined);
  }
}

const loadCounty = () =>{
  var cs = Country.getAllCountries();
  var list:SelectOption[] = [];
  for(var obj of cs){
    list.push({label: obj.name, value: obj});
  }
  setCountries(list);
}

const loadState = () => {
  if(logindata.address.country.isoCode){
    var s = State.getStatesOfCountry(logindata.address.country.isoCode);
    var list:SelectOption[] = [];
    for(var obj of s){
      list.push({label: obj.name, value: obj});
    }
    setStates(list);
  }
}

const loadCity = () => {
  if(logindata.address.state.isoCode && logindata.address.country.isoCode){
    var c = City.getCitiesOfState(logindata.address.country.isoCode, logindata.address.state.isoCode);
    var list:SelectOption[] = [];
    for(var obj of c){
      list.push({label: obj.name, value: obj});
    }
    setCities(list);
  }
}

const setCountryOption = (opt:any) =>{
  logindata.address.country.isoCode = opt.value.isoCode;
  logindata.address.country.name = opt.value.name;
  logindata.address.state.isoCode = '';
  logindata.address.state.name = '';
  setLoginData({...logindata});
}

const setStateOption = (opt:any) =>{
  logindata.address.state.isoCode = opt.value.isoCode;
  logindata.address.state.name = opt.value.name;
  logindata.address.city = '';
  setLoginData({...logindata});
}

const setCityOption = (opt:any) =>{
  logindata.address.city = opt.value.name;
  setLoginData({...logindata});
}


  return (
    <Container component="main" maxWidth="xs" style={{overflow: 'auto', paddingBottom: '100px'}}>
      <CssBaseline />
      {loading ? <CustomLoading /> : ''}
      <div className={classes.paper} style={{marginTop: landscape ? '10px' : '0px'}}>
        {landscape ? <Typography component="h1" variant="h5" className={classes.loginHeading} >
           {key ? 'Update details' : 'Add Employee'}
        </Typography> : ''}
        <form className={classes.form}  noValidate>
        <Grid container justify="space-evenly" >    
            <Grid container spacing={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidName} 
                    errorText={logindata.nameError} required={true} 
                    label="Full Name" name="name" autoComplete="name" value={logindata.name}
                    onchange={checkName} />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidFirmname && logindata.firmName!==''} required={true} 
                    errorText={logindata.firmError} 
                    label="Job Title" name="firmName" autoComplete="firmName" value={logindata.firmName} 
                    onchange={checkFirmname} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomTextInput required={true} error={!logindata.isValidNumber && logindata.number!==''}
                    errorText={logindata.numberError} label="Number" name="number" value={logindata.number} onchange={checkNumber}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidEmail && logindata.email!==''} 
                    errorText={logindata.emailError}
                    label="Email" name="email" type="email" autoComplete="email" value={logindata.email} 
                    onchange={checkEmail} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidGst && logindata.gst!==''}  errorText={logindata.gstError} 
                    label="Aadhar Number" name="gst" autoComplete="gst" value={logindata.gst} 
                    onchange={checkGst} />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Select options={countries} value={{label: logindata.address.country.name ? logindata.address.country.name : 'Select Country', value: logindata.address.country.name ? logindata.address.country : ''}} onFocus={loadCounty} onChange={setCountryOption} ></Select> 
                </Grid> 
                <Grid item lg={4} md={4} sm={6} xs={12}>
                <Select options={states} value={{label: logindata.address.state.name ? logindata.address.state.name : 'Select State', value: logindata.address.state.name ? logindata.address.state : ''}} onFocus={loadState} onChange={setStateOption} ></Select> 
                </Grid> 
                <Grid item lg={4} md={4} sm={12} xs={12}>
                <Select options={cities} value={{label: logindata.address.city ? logindata.address.city : 'Select City', value: logindata.address.city ? logindata.address.city : ''}} onFocus={loadCity} onChange={setCityOption} ></Select> 
                </Grid> 
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <CustomTextInput required={true} 
                    label="Address" name="local" value={logindata.address.local} 
                    onchange={setAddress} />
                </Grid> 
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <CustomTextInput error={!logindata.isValidPin} 
                    errorText={logindata.pinError} required={true}
                    label="Pincode" name="pin" value={logindata.address.pin} 
                    onchange={checkPin} />
                </Grid> 
            </Grid>
            <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={submitForm}
            >
               {key ? 'Update' : 'Add'}
            </Button>
            </Grid>
        </Grid>
        </form>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
        marginBottom: '10px',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));