import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';import 'firebase/auth';
import { ProductCount } from './ProductCount';
import { db } from '../../App';

interface ProductCounts{
    productCounts?:ProductCount[];
}

export function useProductCountKeyInit(key: string):ProductCounts {
  const [productCounts, setProductCounts] = useState<ProductCounts>({}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
      const subscriber = db.collection("productview").doc(key)
      .onSnapshot((doc) => {
         var products: ProductCount[] = [];
          var p:any = doc.data();
          if(p && p.products){
                var count:number = 1;
                for(var prod of p.products){
                    prod.id = count;
                    count = count + 1;
                    products.push(prod);
                } 
                const obj = { productCounts: products };
                setProductCounts(obj);
           }
      });
      // Unsubscribe from events when no longer in use
      return subscriber;
  }, []);

  return productCounts;
}
