import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Product } from '../product/Product';
import ProductService from './ProductService';

interface Products{
    products?:Product[];
}

export function useProductInit():Products {
  const [products, setProducts] = useState<Products>({products: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
      const subscriber = db.collection("product")
      .onSnapshot((querySnapshot) => {
          var data:Product[] = [];
          querySnapshot.forEach((doc) => {
            var prodObj:Product[] = doc.data().products;
            if(prodObj && prodObj.length>0){
              for(var i=0; i < prodObj.length; i++){
                prodObj[i].id = i+1;
                data.push(prodObj[i]);
              }
            }
          });
          const obj = { products: data };
          setProducts(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
  

}, []);
  return products;
}
