import { Container, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import ManufacturerForm from './ManufacturerForm';

const ManufacturerPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  let key = new URLSearchParams(window.location.search).get("key");
  return (
    <IonPage>
         <SideMenu3 title={key? "Update Manufacturer": "Add Manufacturer"} /> 
         <div className={classes.main} >
          <ManufacturerForm  />
          </div>
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ManufacturerPage;