import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import SizeService from './SizeService';
import { useSizeInit } from './SizeData';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Size } from './Size';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';

const SizeListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { sizes } = useSizeInit();
  const [sizeList, setSizeList] = useState<Size[]>();
  let { height, width } = useWindowDimensions();

  const removeClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this size ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete) {
        if(sizes){
          var newSizes = sizes.filter(function( obj ) {
              return obj.name !== item.name;
          });
          var u = await SizeService.addSizeData(newSizes);
          if(u){
            swal("Success !", "Size removed successfully!", "success");
          }
        }
      }
    });
  }

  const addSize = () => {
    const value = swal({
      text: 'Enter Size',
      content: {
        element: "input",
        attributes: {
          placeholder: '32',
          required: true,
        }
      },
      buttons: ["cancel","confirm"]
    });
    value.then(async (size) => {
      if(size){
      const value = swal({
        text: 'Enter Pcs For '+size,
        content: {
          element: "input",
          attributes: {
            placeholder: '1',
            required: true,
            value: 1,
            type: 'number',
          }
        },
        buttons: ["cancel","confirm"]
      });
        value.then(async (pcs) => {
          if(sizes){
              var val:string = GlobalService.capitalizeCamelCase(size);
              var uts:Size[] = [];
              if (sizes && sizes.length > 0){
                if(sizes.some(e => e.name === val)){
                  GlobalService.showtoast(val, "Already exist", "error", undefined, undefined);
                  return;
                }else{
                  if(sizes.length>0){
                    uts = sizes.map(obj => ({...obj}));
                  }
                  var id:any = sizes[sizes.length-1] && sizes[sizes.length-1].id ? sizes[sizes.length-1].id : 0;
                  id = parseInt(id)+1;
                    var u:Size = {name: val, quantity: pcs ? pcs : 1, id: id};
                    uts.push(u);
                }
              }else{
                var u:Size = {name: val, quantity: pcs ? pcs : 1, id: 1};
                uts.push(u);
              } 
              let res = await SizeService.addSizeData(uts && uts.length>0 ? uts : sizes);
              if(res){
                swal("Success !", "Size added successfully!", "success");
              }
            }
        });
      }
    });
  }

  const editClick = (key:any) => {
    navigate("/addSize?key="+key,"forward","push");
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = sizes?.filter((size) => {
      return size?.name?.toLowerCase().includes(lowercasedInput);
    });
    setSizeList(results);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250
    },
    {
      field: 'quantity',
      headerName: 'Pcs',
      width: 250
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return <Button color="secondary"  variant="contained" onClick={() => {removeClick(params.row)}}>Remove</Button>;
      }
    },
  ];

  return (
    <IonPage>
         <SideMenu3 title="Size" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
                {sizeList || sizes ? <div style={{ height: height * 88 / 100, width: '100%' }}>
                    <DataGrid
                    rows={sizeList ? sizeList : sizes ? sizes : []}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                  /> 
                </div> : ''}
            </Grid>
          </div>
          <CustomFab onclick={addSize} />     
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default SizeListPage;