import { Container, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext } from 'react';
import OrderPdfForm from './OrderPdfForm';
import PrintIcon from '@mui/icons-material/Print';
import GlobalService from '../../services/GlobalService';
import CustomFab from '../../components/fab/CustomFab';
import SideMenu3 from '../../components/menu/SideMenu3';

const OrderPdf: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  let key = new URLSearchParams(window.location.search).get("key");
  return (
    <IonPage> 
       <SideMenu3 title="Order Form" /> 
       <div style={{marginLeft: '60px', padding: '10px', paddingBottom: '20px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
  
          <OrderPdfForm  />
          <CustomFab icon={<PrintIcon />} onclick={()=>{
          GlobalService.printDiv("invoice-print",undefined, undefined)
        }} />
          </div>
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default OrderPdf;