import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";
import { ProductCount } from './ProductCount';
import { User } from './User';

function GetUser(){
    
}

export async function addUserData(user:any) {
        var response = false;
        console.log(user);
        await db.collection("users").doc(user.id).set({
            user
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function addUserStatusData(user:any) {
    var response = false;
    var data = {status: user.status, role: user.role};
    await db.collection("users").doc(user.id).collection("organization").doc("private").set({
        data
    })
    .then(() => {
        console.log("Document successfully written!");
        // if(count){
        //     CounterTableService.addCountTableData("userCount","auto",undefined,uid);
        // }
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}

export async function getUserData(id:any) {
    var user: any;
    
    await db.collection("users").doc(id)
    .get()
    .then((doc) =>{
        user = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await user;
}

export async function getUserDataByFilter(name:string, value: string) {
    var list:User[] =  [];
    await db.collection("users").where("user."+name,"==",value)
    .get()
    .then((querySnapshot) =>{
        querySnapshot.forEach((doc)=>{
            list.push(doc.data().user);
        })
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await list;
}

export async function removeUserData(key:any) {
    var response = false;
    console.log(key);
    await db.collection("users").doc(key).delete()
    .then(()=>{
        // CounterTableService.addCountTableData("userCount","minus",undefined,uid);
        response = true;
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return response;
}

export async function getProductView(uid:string | undefined){
    var products: ProductCount[] = [];
    if(uid){
        await db.collection("productview").doc(uid)
        .get()
        .then((doc) =>{
            var p:any = doc.data();
            console.log(p.products)
            if(p && p.products){
                var count:number = 1;
                for(var prod of p.products){
                    prod.id = count;
                    count = count + 1;
                    products.push(prod);
                }
            }
        }).catch((err)=>{
            CommonErrorHandlar.catcherror(err);
        });
    }
    return products;
}

export default { 
    GetUser,
    addUserData,
    getUserData,
    removeUserData,
    addUserStatusData,
    getProductView,
    getUserDataByFilter,
}