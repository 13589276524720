import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './SideMenu3.css';
import { NavContext } from '@ionic/react';
import menuList from './SideMenuList';
import SideMenuItem from './SideMenuItem';
import settings from '../../config/settings';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import LogoutButton from '../common/LogoutButton';
import { logOut } from 'ionicons/icons';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import Search from '../filter/Search';
import NoficitationComponent from './NotificationComponent';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: 'var(--ion-color-primary)',  
      color: 'var(--ion-color-primary-contrast)',   
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: 'var(--ion-color-primary)',  
      color: 'var(--ion-color-primary-contrast)',   
      overflowX: 'hidden',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: 'var(--ion-color-primary)',  
      color: 'var(--ion-color-primary-contrast)',   
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8) + 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

export default function SideMenu3({title, searchevent, keypressevent, placeholder}:any) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { navigate } = useContext(NavContext);

  const { loading, auth } = UseAuthInit();

  const tagItems = menuList.list.map((item, index) =>{
    return (
        <SideMenuItem 
          key = {index}
          data = {item}
        />
    );   
  });


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        
        color="primary"
      >
        <Toolbar>
          <div style={{flexGrow: 1}} >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          </div>
          <div style={{ flexGrow: searchevent || keypressevent ? 6 : 18}} >
          <Typography variant="h6" noWrap >
            {title}
          </Typography>
          </div>
          {searchevent || keypressevent ?   <div style={{flexGrow: 60}} ><Search searchevent={searchevent} keypressevent={keypressevent} placeholder={placeholder} /></div> : ''}
          <div style={{flexGrow: 3}} >
            <NoficitationComponent /> 
          </div>
        </Toolbar>
      </AppBar>
      <Drawer 
        onMouseOver = {handleDrawerOpen}
        onMouseLeave = {handleDrawerClose}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <LogoutButton />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        {tagItems}
        <div className="menu_footer">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
          </IconButton>
        </Toolbar>
        </div>
      </Drawer>
      <div>
        
      </div>
    </div>
  );
}