import { Storage } from '@capacitor/storage';
export default {

    async setStorage(obj:any){
        await Storage.set(obj);
        return true;
    },
  
    async getStorage(key:string){
        const { value } = await Storage.get({ key: key });
        if(value){
            return JSON.parse(value);
        }else{
            return [];
        }
    },
  
    async removeStorage(key:string) {
        await Storage.remove({ key: key });
        return true;
    }
}