import React, { useContext, useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import validation from '../../services/ValidationPattern';
import CustomTextInput from '../../components/common/CustomTextInput';
import { Link } from 'react-router-dom';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import firebaseAuth from '../../hooks/firebaseAuth';
import { NavContext } from '@ionic/react';
import firebase from 'firebase';
import CustomLoading from '../../components/loading/CustomLoading';

export default function LoginForm() {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState<Boolean>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [otpForm, setOtpForm] = useState<boolean>(false);
  const [final, setfinal] = useState<any>();
  let desktop = width > height && height > 500;
  let landscape = height > 500;
  const { navigate } = useContext(NavContext);
  let verify:any;

  const [logindata, setLoginData] = React.useState({
    email: '',
    emailError: 'Enter correct email address',
    numberError: 'Enter correct 10 digit phone number',
    password: '',
    passwordError: 'Min 8 characters and at least 1 number',
    check_textInputChange: false,
    isValidUser: true,
    isValidPassword: true, 
    isValidEmail: true, 
    isValidNumber: true,
    secureTextEntry: true,
    loginError: '',
    otpError: 'Enter correct otp',
    isValidOtp: true,
    otp: '',
    signUp: false,
    name: '',
    number: '',
    showPassword: false,
    signuplink: "/signup",
});

const checkNumber = (val:any) =>{
    var number = val.target.value;
    logindata.number = number;
    if(validation.checkNumber(number)){
        logindata.isValidNumber = true;
        setLoginData({
            ...logindata
        });
    }else{
        logindata.isValidNumber = false;
        setLoginData({ ...logindata});
    }
    
}

const checkOtp = (val:any) =>{
  var otp = val.target.value;
  logindata.otp = otp;
  if(validation.checkOtp(otp)){
      logindata.isValidOtp = true;
      setLoginData({
          ...logindata
      });
      setButtonDisabled(false);
  }else{
      logindata.isValidOtp = false;
      setButtonDisabled(true);
      setLoginData({ ...logindata});
  }
  
}

const submitForm = ()=>{
    if(logindata.isValidNumber && logindata.number!==''){
        // captcha
        setLoading(true);
        setButtonDisabled(true);
        let verify = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container", {
                size: "invisible"
            }
        );
        firebaseAuth.signinusingphone("+91"+logindata.number, verify).then((result) => {
          // code sent
          if(result){
            setfinal(result);
            console.log("code sent");
            setOtpForm(true);
            setButtonDisabled(true);
            setLoading(false);
          }else{
            setButtonDisabled(false);
            setLoading(false);
          }
      })
      .catch((err) => {
        setButtonDisabled(false);
        setLoading(false);
        GlobalService.showtoast("error","Failed to login","error",undefined,undefined);
      });
    }else{
        GlobalService.showtoast('login', settings.loginError, "error",undefined,undefined);
    }
}

const ValidateOtp = () => {
  setLoading(true);
  if (logindata.isValidOtp || logindata.otp !== '' || final !== null){
    final.confirm(logindata.otp).then((result:any) => {
        // success
        if(result){
          GlobalService.showtoast("successOtp","Sign in successfully","success",undefined,undefined);
          setLoading(false);
        }else{
          setLoading(false);
          GlobalService.showtoast("errorotp","Login Failed","error",undefined,undefined);
        }
    }).catch((err:any) => {
        GlobalService.showtoast("errorotp","Login Failed","error",undefined,undefined);
        setLoading(false);
    })
  }else{
    setLoading(false);
    GlobalService.showtoast("errorotp","Login Failed","error",undefined,undefined);
  }
}

  return (
    <Container component="main" maxWidth="xs">
      {loading ? <CustomLoading /> : ''}
      <CssBaseline />
      <div className={classes.paper} style={{marginTop: landscape ? height*10/100 : '0px'}}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {landscape ? <Typography component="h1" variant="h5" className={classes.loginHeading} >
          Sign in
        </Typography> : ''}
         {otpForm ? <CustomTextInput error={!logindata.isValidOtp} 
            errorText={logindata.otpError}
            required={true} label="OTP" value={logindata.otp} name="otp" onchange={checkOtp} /> :
           <CustomTextInput error={!logindata.isValidNumber} 
            errorText={logindata.numberError} required={true} 
            label="Phone Number" name="number"  value={logindata.number}  autoComplete="number" 
            onchange={checkNumber} /> }

          {otpForm ? '' : <div id="recaptcha-container"></div>}

          <Button
            fullWidth
            variant="contained"
            disabled = {buttonDisabled}
            className={landscape ? classes.submit : classes.submit2}
            onClick = {otpForm ? ValidateOtp : submitForm}
          >
           {otpForm ? 'Submit Otp' : 'SEND OTP'}
          </Button>
          {otpForm ? <Button
            fullWidth
            variant="contained"
            className={landscape ? classes.submit : classes.submit2}
            onClick = {()=>{
              setOtpForm(false);
              setButtonDisabled(false);
              logindata.otp = '';
              setLoginData({ ...logindata});
            }}
          >
           Back
          </Button> : ''}
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: theme.spacing(1, 0, 1),
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));