import { IonBadge, NavContext } from "@ionic/react";
import { Card, CardActionArea, CardContent, Grid, Popover } from "@material-ui/core";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Button } from "@mui/material";
import { height, minWidth } from "@mui/system";
import React, { useContext, useState } from "react";
import { messaging } from "../../App";
import settings from "../../config/settings";
import GlobalService from "../../services/GlobalService";
import LocalStorageService from "../../services/LocalStorageService";

const NoficitationComponent = () => {

    const [notifications, setNotifications] = useState<[]>();
    const [anchorE2, setAnchorE2] = React.useState<HTMLButtonElement | null>(null);
    const openNotification = Boolean(anchorE2);
    const notificationId = openNotification ? 'notification-popover' : undefined;
    const { navigate } = useContext(NavContext);

    const handleClickNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorE2(event.currentTarget);
      };
    
      const handleCloseNotification = () => {
        setAnchorE2(null);
      };

    if(!notifications){
        var lastNotifications:any = LocalStorageService.getStorage("notifications");
        lastNotifications.then((res)=>{
            setNotifications(res.reverse());
        })
    }  
    
    messaging.onMessage(async (notification:any) => {
        console.log('Notification received!', notification);
        var lastNotifications:any = await LocalStorageService.getStorage("notifications");
        var noti:any = notification.notification;
        noti.date = GlobalService.getIndianDateFormatFromDateObject(new Date());
        lastNotifications.push(noti);
        setNotifications(lastNotifications.reverse());
        await LocalStorageService.setStorage({key: "notifications", value: JSON.stringify(lastNotifications)})
        GlobalService.shownotification(notification.notification.tag,notification.notification.title,"info",undefined,undefined,()=>{console.log("navigate")});
      });
      
    return (
        <div>
        <Button style={{color: 'var(--ion-color-secondary)', position: 'absolute', right: '0px', top: '10px'}} aria-describedby={notificationId} onClick={handleClickNotification}><NotificationsActiveIcon style={{fontSize: '34px'}} /></Button>
        {notifications && notifications.length>0 ? <IonBadge style={{position: 'absolute', right: '10px', top: '10px'}} color="secondary">{notifications.length ? notifications.length : 0}</IonBadge> : ''}
        <Popover
            id={notificationId}
            open={openNotification}
            anchorEl={anchorE2}
            onClose={handleCloseNotification}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
            <Grid container style={{ cursor: 'context-menu', padding: '10px', minWidth: '400px', maxHeight: '400px'}} >
                    <Grid item xl={12} lg={12} sm={12} >
                        {notifications && notifications.length>0 ? 
                            notifications.map((item:any,index:number)=>{
                                return (
                                    <Card style={{width: '100%', margin: '5px'}} key={"noti"+index} onClick={()=>{navigate(item.click_action,"forward","push");}}>
                                        <CardActionArea>
                                        <CardContent>
                                        <Grid container justify="space-evenly" style={{padding: '5px', height: '40px', cursor: 'context-menu'}} >
                                            <Grid container spacing={3}>  
                                                <Grid item xs={4} sm={4} md={3} lg={2}>
                                                    <img style={{width: '80px'}}  src={item.icon ? item.icon : item.image ? item.image : item.data && item.data.image ? item.data.image : settings.imagePlaceholder}></img>
                                                </Grid>
                                                <Grid item xs={8} sm={8} md={9} lg={10}>
                                                    <span style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: '14px'}}><b>{item.title}</b></span><br style={{content: '""', margin: '.8em', display: 'block', marginBottom: '-5px'}}></br>
                                                    {item.body ? <span style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: '12px'}}>{item.body}</span> : ''}<br></br>
                                                    <span style={{marginLeft: '5px', color: 'var(--ion-color-primary)', fontSize: '12px'}}>{item.date}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )
                            })
                            : <p>No notifications</p>}
                    </Grid>
                </Grid>
            </Popover>
        </div>
    )
}

export default NoficitationComponent;